import React, { useCallback, useContext, useEffect, useState } from "react";
import Context from "../../context/context";
import { useHistory, useLocation } from "react-router-dom";

import elettromec from "../../assets/elettromec.svg";

import { FiSearch, FiX } from "react-icons/fi";

import "./style.scss";

const Header = () => {
  let history = useHistory();
  const pathName = useLocation().pathname;

  const {
    showHeader,
    showMenu,
    setShowMenu,
    isSigned,
    Elettromec,
    categoriesFilter,
    setSelectedCategory,
    location,
    setLocation,
    searchMenuOpen,
    setSearchMenuOpen,
    showAllCategories,
    setShowAllCategories,
  } = useContext(Context);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [stringSearched, setStringSearched] = useState("");

  useEffect(() => {
    setShowAllCategories(false)
    setLocation(pathName);
    setFilteredCategories([]);
  }, [pathName]);

  const handleSearchOption = useCallback(
    (event) => {
      if (event && event !== "") {
        let result;

        if (event === "*") {
          if (stringSearched !== "") {
            result = categoriesFilter.filter((item) =>
              item.label.toLowerCase().includes(stringSearched.toLowerCase()),
            );
          } else {
            result = categoriesFilter;
          }
        } else {
          const value = event.target.value;
          setStringSearched(value);

          result = categoriesFilter.filter((item) =>
            item.label.toLowerCase().includes(value.toLowerCase()),
          );
        }

        setFilteredCategories(result);
      } else {
        setFilteredCategories(categoriesFilter);
      }
    },
    [categoriesFilter, filteredCategories],
  );

  const handleSelectCategory = useCallback((category) => {
    setStringSearched(category.label);
    setSelectedCategory(category);

    if (location !== "/pesquisar") {
      setSearchMenuOpen(false);
    }
    setShowMenu(false);
    history.push("/pesquisar");
    setStringSearched("");
  }, []);

  return (
    <>
      {showHeader &&
        <div className="header-div-content">
          <div className="header-div-titulo">
            <img
              src={elettromec}
              onClick={() => {
                history.push("/home");
              }}
              style={{ cursor: "pointer" }}
              alt="elettromec"
            />
            {(isSigned || window.localStorage[`${Elettromec}:isSigned`] === "true") &&
              location !== "/pesquisar" && (searchMenuOpen ? (
                <FiX
                  size={28}
                  onClick={() => {
                    setStringSearched("");
                    setSelectedCategory([]);
                    setSearchMenuOpen(false);
                    setShowMenu(true);
                    setShowAllCategories(false)
                  }}
                  className="header-img-search FadeIn"
                  alt="search"
                />
              ) : (
                <FiSearch
                  size={28}
                  onClick={() => {
                    setStringSearched("");
                    setSearchMenuOpen(!searchMenuOpen);
                    setShowMenu(!showMenu);
                  }}
                  className="header-img-search FadeIn"
                  alt="search"
                />
              ))}
          </div>
          {searchMenuOpen && (
            <div
              className={
                location !== "/pesquisar"
                  ? "header-div-search SearchFadeInDown"
                  : "header-div-search"
              }
            >
              <span className="header-lbl-search-title">
                Digite abaixo o produto que deseja
            </span>
              <div className="header-box-search">
                <input
                  onFocus={() => {
                    handleSearchOption("*");
                    setShowAllCategories(true);
                  }}
                  className="header-input-search"
                  value={stringSearched}
                  onChange={(event) => handleSearchOption(event)}
                  type="text"
                />
                <FiSearch
                  size={22}
                  className="header-input-search-btn"
                />
                <div className="header-list" style={{ color: "#ffffff" }}>
                  {(stringSearched || showAllCategories) && (
                    <ul>
                      {filteredCategories.map((categoria) => {
                        return (
                          <li
                            style={{ cursor: "pointer" }}
                            key={categoria.value}
                            onClick={() => handleSelectCategory(categoria)}
                          >
                            {categoria.label}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
              <span
                className="header-lbl-search-examples"
                style={{ display: stringSearched || showAllCategories ? "none" : "" }}
              >
                Ex: Refrigeradores, fogões, adegas
            </span>
            </div>
          )}
        </div>
      }
    </>
  );
};

export default Header;
