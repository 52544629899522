import React, { useState, useEffect, useContext } from "react";
import { Fade, Carousel, CarouselItem, CarouselIndicators, Spinner } from "reactstrap";
import { useRouteMatch, useHistory } from "react-router-dom";
import { HOST } from "../../services/api";
import { BsPlusSquare, BsDashSquare, BsTools, BsFolderFill, BsGearFill, BsChevronLeft } from "react-icons/bs";
import { FiPlayCircle, FiBox } from "react-icons/fi"
import VideoModal from "../shared/video";

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

import Context from "../../context/context";
import ProjectList from "../projects";
import LoadingProductDetail from "./loading-product-details";
import { Profiles } from "../../utils/profiles";

import "./style.scss"
import Product3DModal from "./3d-viewer";
import { simpleText2br } from "../../utils";

import { PdfProduct } from "./pdf-product";

const ProdutoDetalhes = ({ ...props }) => {

  const history = useHistory();
  const { params } = useRouteMatch();
  const id = params.id;
  const goBack =
    props.location.state &&
    props.location.state.origin &&
    props.location.state.origin === "filter";

  const [galeria, setGaleria] = useState([]);
  const [product, setProduct] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [is3dModalOpen, setIsModal3dOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [isFichaTecnicaOpen, setIsFichaTecnicaOpen] = useState(false);
  const [isDownloadsOpen, setIsDownloadsOpen] = useState(false);
  const [isOpenDownloadPDF, setIsOpenDownloadPDF] = useState(false);

  const {
    showMenuProject,
    setShowMenuProject,
    Elettromec
  } = useContext(Context);



  async function getProduct() {
    try {
      const client = new ApolloClient({
        uri: `${HOST}/graphql`,
        cache: new InMemoryCache(),
      });

      const { data } = await client.query({
        query: gql`
              query Produtos {
                produtos(where: { id: ${id} }) {
                  id
                  titulo
                  descricao
                  referencia
                  ficha_tecnica: ficha_tecnica_valores {
                    id
                    label: ficha_tecnica {
                      value: nome
                    }
                    value: nome_exibicao
                  }
                  link_gabarito
                  link_manual
                  link_guia_comercial
                  galeria {
                    url
                    mime
                    hash
                  }
                  video_youtube
                  arquivos_3d {
                    id
                    name
                    mime
                    url
                  }
                  arquivos {
                    id
                    url
                  }
                }
              }
            `,
      });


      let index = 1, galleryYoutube = [];
      if (data.produtos[0].video_youtube) {
        const youtubeLinks = data.produtos[0].video_youtube.split(';')
          .map(x => x.replace("\n", ""))
          .filter(x => x && x !== "")
        if (youtubeLinks)
          galleryYoutube = youtubeLinks.map(video => {
            return {
              hash: `youtube-video${index++}`,
              mime: "video",
              url: video,
              embed: video.replace("watch?v=", "embed/"),
              thumbnail: video.replace("www", "img").replace("watch?v=", "vi/") + "/hqdefault.jpg"
            }
          })
      }

      if (data.produtos[0].arquivos && data.produtos[0].arquivos.length > 0) {
        galleryYoutube = [...galleryYoutube,
        {
          hash: "3DFile",
          mime: "3DFile",
          url: data.produtos[0].arquivos[0].url
        }]
      }

      const gallery = [...data.produtos[0].galeria, ...galleryYoutube]

      setProduct(data.produtos[0]);
      setGaleria(gallery);
    } catch (error) { }
  }

  useEffect(() => {
    setShowMenuProject(false)

    getProduct()
    window.scrollTo(0, 0);
  }, [id]);


  const next = () => {
    //if (animating) return;
    const nextIndex = activeIndex === galeria.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    //if (animating) return;
    const nextIndex = activeIndex === 0 ? galeria.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const toggleFichaTecnica = () => {
    setIsFichaTecnicaOpen(!isFichaTecnicaOpen);
    setIsDownloadsOpen(false);
  };

  const toggleDownload = () => {
    setIsDownloadsOpen(!isDownloadsOpen);
    setIsFichaTecnicaOpen(false);
  };

  const toggleModal = () => {
    setIsVideoModalOpen(!isVideoModalOpen);
  }

  const toggle3DModal = () => {
    setIsModal3dOpen(!is3dModalOpen);
  }

  const slides = galeria.map((item, index) => {
    return (
      <CarouselItem
        key={index}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        {
          item.mime.includes("image") ?
            <div className="p-d-conteudo-carousel">
              <img
                className="p-d-img-conteudo"
                src={item.url}
                alt={item.hash}
              />
            </div>
            : item.mime.includes("video") ?
              <div className="p-d-conteudo-carousel"
                onClick={() => {
                  setSelectedVideo(item)
                  toggleModal()
                }}>
                <img
                  className="p-d-img-conteudo"
                  src={item.thumbnail}
                  alt={item.hash}
                />
                <div className="prdt-bg-overlay">
                  <FiPlayCircle size={50} />
                </div>

              </div> :
              <div className="p-d-conteudo-carousel"
                onClick={() => {
                  toggle3DModal()
                }}>
                {
                  product.galeria && product.galeria.length > 0 &&
                  <img
                    className="p-d-img-conteudo"
                    src={product.galeria[0].url}
                    alt={product.galeria[0].hash}
                  />
                }
                <div className="prdt-bg-overlay">
                  <FiBox size={50} />
                  <span>Visualização 3D</span>
                </div>
              </div>
        }
      </CarouselItem>
    );
  });

  return (
    <div className="prdt-div-content">
      <div className="prdt-div-header">
        <div className="prdt-btn-back">
          <button
            onClick={() => {
              goBack ? history.goBack() : history.push("/produtos");
            }}
          >
            <BsChevronLeft />
            <span>VOLTAR</span>
          </button>
          <div className="prdt-btn-pdf" onClick={() => setIsOpenDownloadPDF(true)}>
            {isOpenDownloadPDF ?
              <>
                <Spinner className="share-pdf-spiner" color="light" />
                {setTimeout(() => {
                  setIsOpenDownloadPDF(false)
                  clearTimeout()
                }, 500)}
              </>
              :
              <PdfProduct product={product} />
            }
          </div>
        </div>
      </div>
      {
        product && product.titulo ?
          <>
            <Carousel
              interval={false}
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              <CarouselIndicators
                items={galeria}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
            </Carousel>
            <div className="prdt-div-carousel-content">
              <span className="prdt-lbl-reference">{`${product.referencia}`}</span>
              <span className="prdt-lbl-title">{product.titulo}</span>
              <br />
              <span className="prdt-lbl-description">{simpleText2br(product.descricao)}</span>
            </div>

            <table className="prdt-tbl-data">
              <tbody>
                {product.ficha_tecnica && product.ficha_tecnica.length > 0 &&
                  <tr>
                    <td className="prdt-td-icon" onClick={toggleFichaTecnica}>
                      <div>
                        {isFichaTecnicaOpen ? <BsDashSquare /> : <BsPlusSquare />}
                      </div>
                    </td>
                    <td className="prdt-td-text" onClick={toggleFichaTecnica}>
                      FICHA TÉCNICA
                    </td>
                  </tr>
                }
                <tr
                  style={{
                    display: isFichaTecnicaOpen ? "" : "none",
                  }}
                >
                  <td colSpan={2}>
                    <div className="p-d-div-dados-ocultos">
                      {
                        product.ficha_tecnica && product.ficha_tecnica.length > 0 &&
                        product.ficha_tecnica.map((atrib) => {
                          return (
                            <div key={atrib.id}>
                              <span className="prdt-lbl-table-title">
                                {atrib.label?.value}
                              </span>
                              <br />
                              <span className="prdt-lbl-table-details">{atrib.value}</span>
                              <br />
                              <hr className="prdt-hr" />
                            </div>
                          );
                        })
                      }
                    </div>
                  </td>
                </tr>
                {
                  (product.link_gabarito || product.link_guia_comercial || product.link_manual || product.arquivos_3d) &&
                  <tr>
                    <td className="prdt-td-icon" onClick={toggleDownload}>
                      <div>
                        {isDownloadsOpen ? <BsDashSquare /> : <BsPlusSquare />}
                      </div>
                    </td>
                    <td className="prdt-td-text" onClick={toggleDownload}>
                      DOWNLOADS
                    </td>
                  </tr>
                }
                <tr
                  style={{
                    display: isDownloadsOpen ? "" : "none",
                  }}
                >
                  <td colSpan={2}>
                    <div className="p-d-div-dados-ocultos">
                      <span>Baixe as informações técnicas desse produto:</span>
                      <div className="prdt-div-list-download">
                        <ul className="prdt-list-download" style={{ padding: 0 }}>
                          <li>
                            {product.link_gabarito && (
                              <a href={product.link_gabarito} target="_blank" rel="noreferrer"><BsTools />Gabarito</a>
                            )}
                          </li>
                          <li>
                            {product.link_manual && (
                              <a href={product.link_manual} target="_blank" rel="noreferrer"><BsFolderFill />Manual</a>
                            )}
                          </li>
                          <li>
                            {product.link_guia_comercial && (
                              <a href={product.link_guia_comercial} target="_blank" rel="noreferrer"><BsGearFill />Guia Comercial</a>
                            )}
                          </li>
                          {
                            product.arquivos_3d && product.arquivos_3d.length > 0 && parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === Profiles.ArquitetoDesigner &&
                            <ul className="prdt-list-download" style={{ padding: 0 }}>
                              <span className="prdt-downloads-title">Arquivos 3D</span>
                              {
                                product.arquivos_3d.map(file => {
                                  return (
                                    <ul className="prdt-list-download" style={{ padding: 0 }}>
                                      <li><a href={file.url}>{file.name}</a></li>
                                    </ul>
                                  )
                                })
                              }
                            </ul>
                          }
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {
              parseInt(window.localStorage[`${Elettromec}:userProfile`]) !== Profiles.Consumidor &&
              parseInt(window.localStorage[`${Elettromec}:userProfile`]) !== Profiles.AssistenciaTecnica &&
              <div className="prdt-lbl-add-to-project">
                <button type="button" onClick={() => setShowMenuProject(true)}>{'Adicionar ao projeto'}</button>
              </div>
            }
            <VideoModal open={isVideoModalOpen} toggle={toggleModal} url={selectedVideo?.embed} hash={selectedVideo?.hash} />
            <Product3DModal open={is3dModalOpen} toggle={toggle3DModal} url={product.arquivos[0]?.url} hash={selectedVideo?.hash} />
            {showMenuProject && <ProjectList productId={id} productName={product.titulo} />}
          </>
          :
          <div style={{ width: "90%" }}>
            <LoadingProductDetail />
          </div>
      }
    </div>
  );
};

export default ProdutoDetalhes;
