import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

import './pages/login/style.scss'

ReactDOM.render(

  <>
    <App />
  </>
  ,
  document.getElementById('root')
);

