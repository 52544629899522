import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import api, { HOST } from "./api";

export const getInfoPage = async (location) => {
  try {
    const client = new ApolloClient({
      uri: `${HOST}/graphql`,
      cache: new InMemoryCache(),
    });
    let { data } = await client.query({
      query: gql`
                    query getPagina {
                        paginas(where: { link: "${location}" }) {
                            id
                            link
                            descricao
                        }
                    }
                `,
    });
    return data.paginas[0]
  } catch (error) {
    console.log(error);
  }
};

export async function insertLatLngTechnicalAssistance(
  id,
  data,
  token
) {
  try {
    if (!token)
      return null

    const req = await api.put(`/assistencia-tecnicas/${id}`,
      data, {
      headers: {
        Authorization:
          `Bearer ${token}`,
      }
    });

    return req.data
  }
  catch (error) {
    return Error("Erro ao salvar assistência técnica.")
  }
}

export async function insertLatLngWhereFind(
  id,
  data,
  token
) {
  try {
    if (!token)
      return null

    const req = await api.put(`/locais-revendas/${id}`,
      data, {
      headers: {
        Authorization:
          `Bearer ${token}`,
      }
    });

    return req.data
  }
  catch (error) {
    return Error("Erro ao salvar latitude/longitude")
  }
}