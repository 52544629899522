export const passwordValidatorMessage = "A senha deve conter pelo menos 6 caracteres, sendo pelo menos um número, um carácter especial, uma letra maiúscula e uma minúscula"
export const passwordValidatorEmptyMessage = "Por favor, informe sua senha"
export const passwordValidatorEquals = "Senhas não coincidem, por favor, verifique"
export const passwordValidatorEmptyConfirmation = "Por favor, informe a confirmação da senha"


export const nameValidatorMessage = "Por favor, informe seu nome"

export const emailValidatorEmptyMessage = "Por favor, informe seu e-mail"
export const emailValidatorInvalidMessage = "Endereço de e-mail inválido"
export const emailAlreadyExist = "Este e-mail ja está sendo utilizado"

export const phoneValidatorMessage = "Por favor, informe o telefone completo"
export const phoneValidatorEmptyMessage = "Por favor, informe o telefone"

export const profileValidatorMessage = "Por favor, informe sua função"

export const accountCreateSuccessMessage = "Conta criada com sucesso"

export const generalSaveError = "Erro ao salvar, por favor tente novamente mais tarde"