import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';

export const SuccessScreen = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Garantia enviada com sucesso
      </p>
      <button 
        type="button" 
        className={styles.button}
        onClick={() => history.push('/home')}
      >
        INÍCIO
      </button>
    </div>
  )
}
