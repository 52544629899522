import React, { useContext, useEffect, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import { createProject, getProjectInfo, updateProject } from '../../services/projects/index'
import { Spinner } from 'reactstrap'
import validateInfo from './validateInfo'

import './style.scss'
import '../../style.scss'
import Context from '../../context/context';

const NewProject = () => {

    const history = useHistory()
    const location = useLocation()

    const [projectName, setProjectName] = useState()
    const [clientName, setClientName] = useState()
    const [loading, setLoading] = useState(false)
    const [projectNameValidator, setProjectNameValidator] = useState("")
    const [projectValidator, setProjectValidator] = useState("")
    const [textButton, setTextButton] = useState("+ CRIAR PROJETO")
    const { Elettromec } = useContext(Context)
    const [project, setProject] = useState()


    useEffect(() => {
        window.scrollTo(0, 0)
        if (location.state && location.state.project) {
            validateProject()
        }
        else {
            setTextButton("+ CRIAR PROJETO")
        }
    }, []);

    async function validateProject() {
        const verifyProject = await getProjectInfo(window.localStorage[`${Elettromec}:token`], location.state.project.id)

        if (!verifyProject)
            history.push('/projetos')

        setProject(location.state.project)
        setTextButton("SALVAR")
        setProjectName(location.state.project.nome_projeto)
        if (location.state.project.nome_cliente)
            setClientName(location.state.project.nome_cliente)
    }

    async function handleProject() {
        try {
            setLoading(true)
            const errors = validateInfo({ projectName, clientName })

            setProjectNameValidator(errors.projectName)
            if (errors.error) {
                setLoading(false)
                return;
            }

            if (location.state && location.state.project) {
                const data = {
                    nome_projeto: projectName,
                    nome_cliente: clientName
                }
                await updateProject(project.id, null, null, data, window.localStorage[`${Elettromec}:token`])
                setLoading(false)
                history.push(`/projetos`)
            }
            else {
                const data = await createProject(projectName, clientName, null, window.localStorage[`${Elettromec}:token`])
                if (!data.id) {
                    setLoading(false)
                    setProjectNameValidator(data)
                    return
                }
                setLoading(false)
                history.push(`/projetos/${data.id}`)
            }
        }
        catch (error) {
            setLoading(false)
            setProjectValidator(error)
        }
    }

    return (
        <div className="project-div-content">

            <div className="project-div-header">
                <button type="button" onClick={() => { window.history.back(); }}>
                    <BsChevronLeft />
                    <span>VOLTAR</span>
                </button>

                <span className="lbl-nossos">{location.state && location.state.project ? "EDITAR" : "NOVO"}</span>
                <span className="lbl-produtos">Projeto</span>
            </div>

            <div className="project-div-form">
                <span>Nome do projeto</span>
                <input
                    type="text"
                    id="projectName"
                    value={projectName}
                    onFocus={() => {
                        setProjectNameValidator("")
                        setProjectValidator("")
                    }}
                    className="general-input"
                    onBlur={(e) => setProjectName(e.target.value)}
                    onChange={(e) => {
                        setProjectName(e.target.value);
                        // setEmailValidate(true);
                        // setFormValidate(true);
                    }}
                    style={{
                        border: projectNameValidator !== "" ? "1px solid #FF0000" : "",
                    }}
                />
                {projectNameValidator !== "" &&
                    <div className="new-project-div-validator"><span className="new-project-lbl-validator">{projectNameValidator}</span></div>
                }

                <span>Nome do cliente </span><span style={{ font: 'normal normal 300 14px/14px Josefin Sans' }}>(opcional)</span>
                <input
                    type="text"
                    id="clientName"
                    value={clientName}
                    className="general-input"
                    onBlur={(e) => setClientName(e.target.value)}
                    onChange={(e) => {
                        setClientName(e.target.value);
                    }}
                    onFocus={() => {
                        setProjectValidator("")
                    }}
                />
                {
                    projectValidator !== "" &&
                    <span className="new-project-lbl-validator">{projectValidator}</span>
                }
                <div className="project-a-form">
                    {loading ? <Spinner color="light" /> : <button onClick={handleProject} className="project-lnk-save">{textButton}</button>}
                    <button type="button" onClick={() => { window.history.back(); }} className="project-lnk-cancel">CANCELAR</button>
                </div>
            </div>


        </div>
    )
}


export default NewProject