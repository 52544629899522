import { push as Menu } from "react-burger-menu";
import React from "react";
import menu from "../../assets/menu.svg";
import { Link } from "react-router-dom";
import "./style.scss";

class MenuNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      profile: props.profile,
    };
  }

  showSettings(event) {
    event.preventDefault();
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  render() {
    const menuItems = this.props.menu;

    const closeMenu = () => {
      this.setState({ menuOpen: false });
    };

    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu
        burgerButtonClassName={"FadeIn"}
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
        className="bm-menu-wrap"
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        customBurgerIcon={<img src={menu} alt="Menu" />}
      >
        <div className="lbl-menu">MENU</div>

        <ul>
          {menuItems.map((item) => {
            return (
              item.rota.includes("projeto") ? (
                parseInt(this.state.profile) !== 4 && (
                  <li
                    key={item.id}
                    className="bm-menu-projects"
                  >
                    <i style={{ backgroundImage: `url(${item.imagem.url})` }} />
                    <Link onClick={closeMenu} className="item-menu" to={`/${item.rota}`}>
                      {item.titulo}
                    </Link>
                  </li>
                )
              ) :
                item.rota.includes("logout") ? (
                  <li
                    key={item.id}
                    className="bm-menu-logout"
                  >
                    <i style={{ backgroundImage: `url(${item.imagem.url})` }} />
                    <Link onClick={closeMenu} className="item-menu" to={`/${item.rota}`}>
                      {item.titulo}
                    </Link>
                  </li>
                ) : item.rota.includes("perfil") ? (
                  parseInt(this.state.profile) !== 4 &&
                  <li
                    key={item.id}
                    className="bm-menu-profile"
                  >
                    <i style={{ backgroundImage: `url(${item.imagem.url})` }} />
                    <Link onClick={closeMenu} className="item-menu" to={`/${item.rota}`}>
                      {item.titulo}
                    </Link>
                  </li>
                ) :
                  (
                    <li key={item.id}>
                      <i style={{ backgroundImage: `url(${item.imagem.url})` }} />
                      <Link onClick={closeMenu} className="item-menu" to={`/${item.rota}`}>
                        {item.titulo}
                      </Link>
                    </li>
                  )
            );
          })}
        </ul>
      </Menu>
    );
  }
}

export default MenuNav;
