import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Context from "../../context/context";

const useForm = () => {
  let history = useHistory();
  const { setFilterdFields } = useContext(Context);

  // const [isSubmitting, setIsSubmitting] = useState(false);

  const [filters, setFilters] = useState([]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    let objIndex = filters.findIndex((obj) => obj.type === name);
    filters[objIndex].id = value;
    setFilters(filters);
  };

  const handleChangeSelect = (e) => {
    const { value, type } = e;
    let objIndex = filters.findIndex((obj) => obj.type === type);
    filters[objIndex].id = value;
    setFilters(filters);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filtersSend = filters.filter((filter) => filter.id);
    filtersSend = filtersSend.map((filter) => parseInt(filter.id));
    setFilterdFields(filtersSend);
    history.push("/resultado-busca");
  };

  useEffect(() => {}, [filters]);

  return {
    handleChange,
    filters,
    setFilters,
    handleSubmit,
    handleChangeSelect,
  };
};

export default useForm;
