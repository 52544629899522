import React, { } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import './style.scss'

const ConfirmDialog = (props) => {

    return (
        props && <>
            <Modal centered isOpen={props.open} toggle={props.toggle} contentClassName="comp-confirmation-content">
                <ModalHeader className="comp-confirmation-header">{props.title}</ModalHeader>
                <ModalBody className="comp-confirmation-body">{props.body}</ModalBody>
                <ModalFooter className="comp-confirmation-footer">
                    <button type="button" onClick={props.functionFirstOption}>{props.textFirstOption}</button>{' '}
                    {props.textSecondOption && <button onClick={props.functionSecondOption}>{props.textSecondOption}</button>}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ConfirmDialog