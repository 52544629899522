import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import api, { HOST } from "../api";
import { getUserInfo } from "../users";

async function getProjectInfo(token, projectId) {
    try {
        const userInfo = await getUserInfo(token)

        if (!userInfo)
            throw "Usuário não encontrado"

        const client = new ApolloClient({
            uri: `${HOST}/graphql`,
            cache: new InMemoryCache(),
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        });

        const { data } = await client.query({
            query: gql`
            query getProjetos {
                projetos(where: { usuario: { id: ${userInfo.id} }, ${(projectId ? "id: " + projectId : "")} }) {
                    id
                    nome_projeto
                    nome_cliente,
                    produtos {
                        id
                        titulo
                        referencia
                        galeria {
                            url
                        }
                    }
                    usuario {
                        id
                        username
                    }
                }
              }
          `,
        });

        let dataFinal = data
        if (data && data.projetos.length > 0) {
            dataFinal = { projetos: data.projetos.map((project) => Object.assign({}, project, { qtd_produtos: project.produtos.length, isOpen: false })) }
        }

        return dataFinal
    }
    catch (error) {
        return error
    }
}

async function createProject(
    nome_projeto,
    nome_cliente,
    produtos,
    token
) {
    try {
        const userInfo = await getUserInfo(token)

        if (!userInfo)
            throw "Usuário não encontrado"

        const projects = await getProjectInfo(token)

        if (projects.projetos && projects.projetos.length > 0) {
            const search = projects.projetos.filter(x => {
                return x.nome_projeto.toString().toLowerCase() === nome_projeto.toString().toLowerCase()
            })

            if (search && search.length > 0)
                return "Projeto já existe"
        }

        const { data } = await api.post(`/projetos`, {
            nome_projeto,
            nome_cliente,
            produtos,
            usuario: userInfo.id
        }, {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        });

        return data
    }
    catch (error) {
        throw "Erro ao salvar projeto, tente novamente mais tarde"
    }
}

//productId - use this parameter only when want to remove the product from the project
//productList - use this parameter to add ou delete a product
//data (of the project) - use when want update the project (name, client name)
async function updateProject(
    projectId,
    productId,
    productList,
    data,
    token
) {
    try {
        if (productList) {
            let products = productList
            if (productId) //remove the product
                products = productList.filter((product) => parseInt(product.id) !== parseInt(productId)).map(x => {
                    return {
                        id: x.id
                    }
                });

            const req = await api.put(`/projetos/${projectId}`, {
                produtos: products
            }, {
                headers: {
                    Authorization:
                        `Bearer ${token}`,
                }
            });

            return req.data
        }
        else {
            const req = await api.put(`/projetos/${projectId}`,
                data
                , {
                    headers: {
                        Authorization:
                            `Bearer ${token}`,
                    }
                });

            return req.data
        }
    }
    catch (error) {
        return "Erro ao atualizar projeto. Tente novamente mais tarde"
    }
}

async function deleteProject(
    projectId,
    token
) {
    try {
        const userInfo = await getUserInfo(token)

        if (!userInfo)
            throw "Usuário não encontrado"

        const req = await api.delete(`/projetos/${projectId}`, {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        });

        return req.data
    }
    catch (error) {
        console.log('Error ao criar projeto', error)
    }
}

async function getProjectInfoToPdf(token, projectId) {
    try {
        const userInfo = await getUserInfo(token)

        if (!userInfo)
            throw "Usuário não encontrado"

        const client = new ApolloClient({
            uri: `${HOST}/graphql`,
            cache: new InMemoryCache(),
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        });

        const { data } = await client.query({
            query: gql`
            query getProjetos {
                projetos(where: { usuario: { id: ${userInfo.id} }, ${(projectId ? "id: " + projectId : "")} }) {
                    id
                    nome_projeto
                    nome_cliente,
                    produtos {
                        id
                        titulo
                        descricao
                        referencia
                        ficha_tecnica: ficha_tecnica_valores {
                            id
                            label: ficha_tecnica {
                                value: nome
                            }
                            value: nome_exibicao
                        }
                        link_gabarito
                        link_manual
                        link_guia_comercial
                        galeria {
                            url
                            mime
                            hash
                        }
                        video_youtube
                    }
                    usuario {
                        id
                        username
                    }
                }
              }
          `,
        });

        let dataFinal = data
        if (data && data.projetos.length > 0) {
            dataFinal = { projetos: data.projetos.map((project) => Object.assign({}, project, { qtd_produtos: project.produtos.length, isOpen: false })) }
        }

        return dataFinal
    }
    catch (error) {
        return error
    }
}

export { createProject, updateProject, getProjectInfo, deleteProject, getProjectInfoToPdf }