import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { simpleText2br } from "../../utils";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getInfoPage } from "../../services";


import './style.scss'

const ExtendedWarranty = ({ ...props }) => {
  const [pageInfo, setPageInfo] = useState();
  const location = useLocation().pathname.replace("/", "");

  useEffect(() => {
    async function loadApi(local) {
      const data = await getInfoPage(local)
      if (data)
        setPageInfo(data)
      else
        setPageInfo({})
    }
    loadApi(location)
  }, [location])

  return (
    <>
      <div className="pg-ew-div-content">
        <div className="pg-ew-header">
          <span className="pg-ew-lbl-warranty">Garantia</span>
          <span className="pg-ew-lbl-ew">Estendida</span>
        </div>
        <div className="pg-ew-description">
          {
            pageInfo ? <p>{simpleText2br(pageInfo?.descricao)}</p>
              :
              <SkeletonTheme color="#202020" highlightColor="#444">
                <Skeleton style={{ height: '350px' }} />
              </SkeletonTheme>
          }
        </div>
      </div>
    </>
  );
};

export default ExtendedWarranty;
