import React from 'react'
import CategoriasProdutos from '../../components/products-category';


const ProdutosPage = ({ ...props }) => {

    return (
        <>
            <CategoriasProdutos />
        </>
    )
}

export default ProdutosPage;