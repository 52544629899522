import React, { useState, useEffect, useContext } from "react";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import Geocode from "react-geocode";
import { HOST } from "../../services/api";
import { GoogleMapsComponent } from '../../components/google-maps-component'
import { PlacesAutocompleteComponent } from '../../components/places-autocomplete-component'
import { LoadingList } from '../../components/skeletons/index'
import { distanceMapLimit, getDistanceBetweenPoints } from '../../utils/index'
import { useLocation } from "react-router-dom";
import { getInfoPage, insertLatLngWhereFind } from "../../services";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { simpleText2br } from "../../utils";
import Context from "../../context/context";

import './style.scss'

const OndeEncontrar = () => {
  const { Elettromec } = useContext(Context)
  const location = useLocation().pathname.replace("/", "")
  const [whereFindList, setWhereFindList] = useState([])
  const [addressList, setAddressList] = useState(null)
  const [distanceAddressList, setDistanceAddressList] = useState(null)
  const [address, setAddress] = useState('')
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  })
  const [orderedAddressList, setOrderedAddressList] = useState(null)
  const [loading, setLoading] = useState(false)
  const [emptyListValidator, setEmptyListValidator] = useState(false)
  const [pageInfo, setPageInfo] = useState()

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
  Geocode.setLanguage("pt-br");

  useEffect(() => {
    if (coordinates.lat !== null)
      setDistanceBetweenAddress()
  }, [coordinates])

  useEffect(() => {
    async function loadApi(local) {
      const data = await getInfoPage(local)
      if (data)
        setPageInfo(data)
      else
        setPageInfo({})
    }
    loadApi(location)
  }, [location])

  async function setDistanceBetweenAddress() {
    if (addressList && addressList.length > 0 && addressList[0].lat) {
      try {
        const newAddressList = [...addressList]

        let i = 0
        addressList.map(async (item, index) => {
          const distance = await getDistanceBetweenPoints(coordinates.lat, coordinates.lng, item.lat, item.lng)
          newAddressList[index] = {
            ...item,
            distancia: distance
          }

          i++;
          if (i === newAddressList.length) {
            setDistanceAddressList(newAddressList)
          }
        }
        )
      }
      catch (error) { }
    }
  }

  useEffect(() => {
    if (distanceAddressList) {
      setLoading(true)
      const tempListCity = distanceAddressList
        .filter(item => address.toUpperCase().indexOf(item.cidade.toString().toUpperCase()) > -1 &&
          address.toUpperCase().indexOf(item.estado.toString().toUpperCase()) > -1)
        .sort((a, b) =>
          (a.nome > b.nome ? 1 : -1)
        );

      const tempList = distanceAddressList
        .filter(item => item.distancia < distanceMapLimit && address.toUpperCase().indexOf(item.cidade.toString().toUpperCase()) === -1)
        .sort((a, b) =>
          (a.nome > b.nome ? 1 : -1)
        );

      const newOrderedList = [...tempListCity, ...tempList];

      setEmptyListValidator(newOrderedList.length === 0)
      setOrderedAddressList(newOrderedList)
      setLoading(false)
    }
  }, [distanceAddressList])

  useEffect(() => {
    getListAddress()
  }, [])

  const getListAddress = async () => {
    try {
      const client = new ApolloClient({
        uri: `${HOST}/graphql`,
        cache: new InMemoryCache(),
      });

      let whereFind = await client.query({
        query: gql`
          query WhereFind {
            locaisRevendas(where: { ativo: true }) {
              id
              nome
              cep
              endereco
              numero
              complemento
              bairro
              cidade
              estado
              telefone
              celular
              email
              site
              lat: latitude
              lng: longitude
            }
          }
        `
      });

      const parsedList = whereFind.data.locaisRevendas.map(x => {
        const value = {
          ...x,
          lat: parseFloat(x.lat),
          lng: parseFloat(x.lng)
        }
        return value
      })

      setWhereFindList(parsedList)
      searchLatLngForAddress(parsedList)
    } catch (error) { }
  };

  const handleSelect = async (value) => {
    const { results } = await Geocode.fromAddress(value)
    const latLng = results[0]?.geometry.location;

    setAddress(value)
    setCoordinates(latLng)
  }

  const searchLatLngForAddress = async (list = whereFindList) => {
    try {
      setLoading(true)
      const newAddressList = [...list]

      const promises = list.map(async (item, index) => {
        if (!item.lat || !item.lng) {
          let currentAddress = (item.endereco && item.endereco != null && item.endereco) +
            (item.numero && item.numero != null ? ", " + item.numero : '') +
            (item.bairro && item.bairro != null ? " - " + item.bairro.trim() : '') +
            (item.cidade && item.cidade != null ? " - " + item.cidade.trim() : '') +
            (item.estado && item.estado != null ? " - " + item.estado : '')

          try {
            const response = await Geocode.fromAddress(currentAddress)
            const { lat, lng } = response.results[0].geometry.location;

            await insertLatLngWhereFind(item.id, { latitude: lat.toString(), longitude: lng.toString() }, window.localStorage[`${Elettromec}:token`])

            newAddressList[index] = {
              ...item,
              lat,
              lng
            }
          }
          catch { }
        }
      })

      Promise.all(promises).then((array) => {
        setAddressList(newAddressList)
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="pg-where-find">
        <div className="content-header">
          <span className="content-title-first">Onde</span>
          <span className="content-title-last">Encontrar</span>
        </div>
        <div className="pg-where-findo-description">
          {pageInfo ? <p>{simpleText2br(pageInfo?.descricao)}</p>
            :
            <SkeletonTheme color="#202020" highlightColor="#444">
              <Skeleton style={{ height: '200px' }} />
            </SkeletonTheme>}
        </div>
        <div className="content-description">
          <PlacesAutocompleteComponent
            address={address}
            setAddress={setAddress}
            coordinates={coordinates}
            setCoordinates={setCoordinates}
            handleSelect={handleSelect}
            clearValidator={() => setEmptyListValidator(false)}
          />
          {emptyListValidator && <span className="pg-where-find-no-results">Nenhum resultado encontrado</span>}
          <GoogleMapsComponent
            addressList={orderedAddressList}
            setAddressList={setAddressList}
            coordinates={coordinates}
          />
        </div>
        <div className="pg-where-find-div-list">
          {
            loading ? <LoadingList /> :
              orderedAddressList?.length > 0 && orderedAddressList.map((item) => {
                const url = item.site && item.site.toString().includes('http') ? item.site : `//${item.site}`
                return (
                  <div key={item.id}>
                    <h4>{item.nome}</h4>
                    {item.telefone && <span>{item.telefone}</span>}
                    {item.email && <span>{item.email}</span>}
                    {item.endereco && <span>{item.endereco + (item.numero ? ", " + item.numero : "") + " - " + (item.bairro ? item.bairro : "")}</span>}
                    {item.cidade && <span>{`${item.cidade} - ${item.estado}`}</span>}
                    {item.cep && <span>{item.cep}</span>}
                    {item.site && <a href={url} target="_blank" rel="noreferrer">{item.site} </a>}
                  </div>
                )
              })
          }
        </div>
      </div>
    </>
  )
}

export default OndeEncontrar;