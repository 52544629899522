import React, { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";

import { HOST } from "../../services/api";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

import Context from "../../context/context";
import useForm from "./useForm";

import { FiX, FiSearch } from "react-icons/fi";
import { LoadingFilter } from "./skeleton";

import "./style.scss";

const CategoryFilter = () => {
  const {
    setShowMenu,
    categoriesFilter,
    setSelectedCategory,
    selectedCategory,
    showAllCategories,
    setShowAllCategories
  } = useContext(Context);

  const { setFilters, handleChange, handleChangeSelect, handleSubmit } = useForm();
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [stringSearched, setStringSearched] = useState("");

  const [fields, setFields] = useState([]);
  const [inputTextCategory, setInputTextCategory] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowMenu(true);
    getTechnicalFields();

    window.scrollTo(0, 0);
  }, [setShowMenu, selectedCategory]);

  async function handleClearOptions(e) {
    e.preventDefault()
    const fieldsBkp = fields
    setFields([])
    setLoading(true)
    let timer = setTimeout(() => {
      setFields(fieldsBkp)
      setLoading(false)
    }, 0.2 * 1000);
    return () => {
      clearTimeout(timer);
    };
  }

  const handleSearchOption = useCallback(
    (event, searched = stringSearched) => {

      if (event && event !== "") {
        let result;

        if (event === "*") {
          if (searched !== "") {
            result = categoriesFilter.filter((item) =>
              item.label.toLowerCase().includes(searched.toLowerCase()),
            );
          } else {
            result = categoriesFilter;
          }
        } else {
          const value = event.target.value;
          setStringSearched(value);

          result = categoriesFilter.filter((item) =>
            item.label.toLowerCase().includes(value.toLowerCase()),
          );
        }

        setFilteredCategories(result);
      } else {
        setFilteredCategories(categoriesFilter);
      }
    },
    [categoriesFilter, filteredCategories, setShowAllCategories],
  );

  async function getTechnicalFields() {
    try {
      if (selectedCategory && selectedCategory.value) {
        const client = new ApolloClient({
          uri: `${HOST}/graphql`,
          cache: new InMemoryCache(),
        });
        setLoading(true)

        const { data } = await client.query({
          query: gql`
          query fichaTecnica {
            produtos(where: { categoria: { id: [${selectedCategory.value}] }
              ficha_tecnica_valores: { ficha_tecnica: { permite_filtrar: true } }}, sort: "categoria.ordenacao:asc") {
              categoria {
                Titulo
              }
              ficha_tecnica_valores {
                id
                label: ficha_tecnica {
                  value: nome
                  permite_filtrar
                }
                value: nome_exibicao
              }
            }
          }
        `,
        });

        const retorno = data.produtos.map((x) => x);

        const result = [];
        const map = new Map();
        for (const item of retorno) {
          item.ficha_tecnica_valores.map((ficha) => {
            if (!map.has(ficha.id)) {

              //Filtro para exibir somente os campos que podem ser filtrados
              if (ficha.label?.permite_filtrar !== false) {
                map.set(ficha.id, true); // set any value to Map

                const newValue = {
                  id: ficha.id,
                  ficha_tecnica: ficha.label?.value,
                  value: ficha.value,
                  checked: false
                };

                result.push(newValue);
              }
            }
          });
        }

        setFields(groupBy(result, "ficha_tecnica"));
        let parameters = groupBy(result, "ficha_tecnica");
        setFilters([]);
        let arrayFilters = [];
        parameters.map((param) => {
          if (param[0] && param[0].ficha_tecnica)
            arrayFilters.push({ id: "", type: param[0].ficha_tecnica });
        });
        setFilters(arrayFilters);
        setLoading(false)
      } else {
        setLoading(false)
        setFields([]);
      }
    } catch (error) {
      setLoading(false)
    }
  }

  function groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      // if (val) {
        index = values.indexOf(val);
        if (index > -1) result[index].push(collection[i]);
        else {
          values.push(val);
          result.push([collection[i]]);
        }
      // }
    }
    return result;
  }

  const handleSelectCategory = useCallback((category) => {
    setStringSearched(category.label);
    setSelectedCategory(category);
    // history.push('/pesquisar')
    setStringSearched("");
    setInputTextCategory("");
    setShowAllCategories(false)
  }, []);

  const selectStyles = {
    container: (base) => ({
      ...base,
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      borderRadius: 25,
      border: "1px solid #707070",
      boxShadow: "none",
      background: "black",
      padding: 8,
      cursor: "pointer",
      ":hover": {
        borderColor: "#707070",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      color: "white",
      height: 35,
    }),
    singleValue: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      color: "white",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      background: "transparent",
      color: "white",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 25,
      border: "1px solid #707070",
      background: "black",
      padding: 8,
    }),
    option: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      height: "100%",
      cursor: "pointer",
    }),
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="search-div-search">
          <span className="search-lbl-search-title"></span>
          <div className="search-box-search">
            <input
              className="search-input-search"
              value={inputTextCategory}
              onChange={(event) => {
                setInputTextCategory(event.target.value);
                handleSearchOption(event);
              }}
              onFocus={(event) => {
                if (event.target.value === "") {
                  setStringSearched("");
                }
                handleSearchOption("*", "");
                setShowAllCategories(true);
              }}
              type="text"
            />
            {showAllCategories ?
              <FiX
                size={24}
                onClick={() => {
                  setStringSearched("");
                  setInputTextCategory("")
                  setShowAllCategories(false);
                }}
                className="search-img-search"
                alt="cancel_search"
              /> :
              <FiSearch
                size={24}
                onClick={() => {
                  setShowAllCategories(true);
                }}
                className="search-img-search"
                alt="search"
              />
            }

            <div className="search-list" style={{ color: "#ffffff" }}>
              {(stringSearched || showAllCategories) && (
                <ul>
                  {filteredCategories.map((categoria) => {
                    return (
                      <li
                        key={categoria.value}
                        onClick={() => handleSelectCategory(categoria)}
                      >
                        {categoria.label}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
          <span
            className="search-lbl-search-examples"
            style={{
              color: stringSearched ? "black" : "#707070",
              display: stringSearched || showAllCategories ? "none" : ""
            }}
          >
            Ex: Refrigeradores, fogões, adegas
          </span>
        </div>

        <div className="pg-filter-div-content">
          {selectedCategory && selectedCategory.value && (
            <span className="selected-category">
              {selectedCategory.label}
              <FiX
                size={16}
                onClick={() => {
                  setStringSearched("");
                  setSelectedCategory([]);
                  setShowMenu(true);
                }}
                className=""
                alt="search"
              />
            </span>
          )}
          {loading ?
            <LoadingFilter /> :
            fields &&
            fields.length > 0 &&
            fields.map((ficha_tecnica, index) => {
              return (
                <div key={`f-${index}`}>
                  <div className="content-radio-button">
                    <span> {ficha_tecnica[0].ficha_tecnica}</span>
                  </div>
                  {ficha_tecnica[0].length > 2 ? (
                    <Select
                      styles={selectStyles}
                      placeholder={ficha_tecnica[0].ficha_tecnica}
                      onChange={(e) => {
                        handleChangeSelect(e);
                      }}
                      options={ficha_tecnica.map((option) => {
                        return {
                          value: option.id,
                          name: option.label,
                          label: option.value,
                          type: option.ficha_tecnica,
                        };
                      })}
                      isClearable={false}
                      isSearchable={false}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 20,
                        colors: {
                          ...theme.colors,
                          primary75: "transparent",
                          primary50: "transparent",
                          primary25: "transparent",
                          primary: "transparent",
                        },
                      })}
                    />
                  ) : (
                    <>
                      <div className="container-radio-button">
                        {ficha_tecnica.map((item, index) => {
                          return (
                            <>
                              <div className="content-radio-button" key={`fv-${item.id}`}>
                                <input
                                  key={index}
                                  type="radio"
                                  name={item.ficha_tecnica}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={item.id}
                                />
                                <span>{item.value}</span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          <div className="pg-filter-div-buttons" style={{ visibility: selectedCategory?.value ? 'visible' : 'hidden' }}>
            <button type="submit" className="search-btn">
              FILTRAR
            </button>
            <button type="button" onClick={handleClearOptions} className="pg-filter-span-clear">
              LIMPAR
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CategoryFilter;
