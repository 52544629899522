import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { FiEdit3, FiTrash2, FiX } from 'react-icons/fi';
import Context from "../../context/context";
import { Fade } from 'reactstrap';
import { getProjectInfo, deleteProject } from '../../services/projects/index'
import ConfirmDialog from '../../components/shared/confirmation';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import './style.scss'
import '../../style.scss'

const Project = () => {

    const { Elettromec } = useContext(Context);
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        findProjects();
        window.scrollTo(0, 0)
    }, []);

    async function findProjects() {
        try {
            setLoading(true)
            const data = await getProjectInfo(window.localStorage[`${Elettromec}:token`])

            let dataFinal
            if (data && data.projetos.length > 0) {
                dataFinal = data.projetos.map((project) => Object.assign({}, project, { qtd_produtos: project.produtos.length, isOpen: false }))
            }

            setProjects(dataFinal)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    function handleProjectClick(id) {
        history.push(`/projetos/${id}`)
    }

    const handleProjectDetails = useCallback((project) => {
        const index = projects.indexOf(project)
        setProjects(
            state => state.map((proj, i) => {
                if (i === index) {
                    proj.isOpen = !proj.isOpen
                }
                else
                    proj.isOpen = false
                return proj
            }))
    }, [projects])

    function handleEditProject(project) {
        history.push(`/novo-projeto`,
            { project: project })
    }

    async function handleExcludeProject() {
        await deleteProject(selectedProject, window.localStorage[`${Elettromec}:token`])
        await findProjects()
        toggle()
    }

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    return (
        <div className="project-div-content">

            <div className="project-div-header">
                <span className="project-lbl-title-first">meus</span>
                <span className="project-lbl-title-last">Projetos</span>
            </div>
            {!loading ? (
                <div className="new-project-div-form" style={{ border: (projects && projects.length > 0 ? 'solid 1px #ffffff' : 'none') }}>
                    {
                        projects && projects.length > 0 && projects.map(project => {
                            return (
                                <div className="new-project-div-item" key={project.id}>
                                    <div className="new-project-div-list">
                                        <button type="button" className="new-project-div-list-info" onClick={() => handleProjectClick(project.id)}>
                                            <span className="new-project-lbl-title" onClick={() => handleProjectClick(project.id)}>
                                                {project.nome_projeto}
                                            </span>
                                            <span className="new-project-lbl-products" onClick={() => handleProjectClick(project.id)}>
                                                {
                                                    project.qtd_produtos > 0 ? (
                                                        project.qtd_produtos > 1 ? `${project.qtd_produtos} produtos` : `${project.qtd_produtos} produto`
                                                    ) : "Nenhum produto"
                                                }
                                            </span>
                                        </button>
                                        <div className="new-project-div-view" onClick={() => handleProjectDetails(project)}>
                                            {project.isOpen ? <FiX size={20} /> : <FiEdit3 size={20} />}
                                        </div>
                                    </div>
                                    <Fade in={project.isOpen} timeout={200} >
                                        <div className="project-div-edit" style={{ display: project.isOpen ? "" : "none" }}>
                                            <button type="button" onClick={() => handleEditProject(project)}><FiEdit3 size={20} />Editar</button>
                                            <button type="button" onClick={() => {
                                                toggle();
                                                setSelectedProject(project.id);
                                            }}><FiTrash2 size={20} />Excluir</button>
                                        </div>
                                    </Fade>
                                    <ConfirmDialog open={open} toggle={toggle}
                                        title={"Exclusão de projeto"} body={"Tem certeza que deseja excluir o projeto?"}
                                        textFirstOption={"Sim"} functionFirstOption={handleExcludeProject} id={project.id}
                                        textSecondOption={"Não"} functionSecondOption={toggle} />
                                </div>
                            )
                        })
                    }
                </div>
            ) : (
                <div className="ctprd-skeleton">
                    <SkeletonTheme color="#202020" highlightColor="#444">
                        <Skeleton style={{ height: '150px' }} />
                    </SkeletonTheme>
                </div>
            )}

            <div className="project-a-form">
                <Link to="novo-projeto" className="project-lnk-create">+ CRIAR NOVO PROJETO</Link>
            </div>
        </div>
    )
}


export default Project