import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import React, { useState, useEffect, useContext } from "react";
import { FiX } from "react-icons/fi";
import { HOST } from "../../services/api";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import Context from "../../context/context";
import SearchResultLoading from './loadind-search-result'

import "./style.scss";

const SearchResult = () => {
  const { filteredFields, setFilterdFields, setShowMenu, selectedCategory, setSelectedCategory } = useContext(Context);

  let history = useHistory();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [technicalFields, setTechnicalFields] = useState([]);

  async function getTechnicalFields() {
    try {
      const category = { id: selectedCategory.id, value: selectedCategory.label, category: true }
      if (filteredFields && filteredFields.length > 0) {
        const client = new ApolloClient({
          uri: `${HOST}/graphql`,
          cache: new InMemoryCache(),
        });

        const { data } = await client.query({
          query: gql`
                    query fichatecnica {
                        fichaTecnicaValores(where: {id: [${filteredFields.toString()}]}) {
                        id
                        value: nome_exibicao,
                        label: ficha_tecnica {
                            value: nome
                            }
                        }
                    }
                 `,
        });

        const newData = [category,
          ...data.fichaTecnicaValores]

        setTechnicalFields(newData);
      }
      else {
        setTechnicalFields([category])
      }
    } catch (error) {
    }
  }

  async function getProducts() {
    try {
      setLoading(true)
      const client = new ApolloClient({
        uri: `${HOST}/graphql`,
        cache: new InMemoryCache(),
      });

      const { data } = await client.query({
        query: gql`
                    query Produtos  {
                        produtos(where: { ficha_tecnica_valores: { id: [${filteredFields.toString()}] }, categoria: { id: ${selectedCategory.value}} },
                                  sort: "categoria.ordenacao:asc"
                        ) {
                            id
                            titulo
                            ficha_tecnica: ficha_tecnica_valores {
                                id
                                label: ficha_tecnica {
                                value: nome
                                    }
                                value: nome_exibicao
                            },
                            galeria {
                                id,
                                url
                            },
                            referencia,
                            link_gabarito,
                            link_manual,
                            link_guia_comercial   
                    }
                }
            `,
      });
      const resul = data.produtos.filter((product) => {
        let found = [];
        product.ficha_tecnica.map((ficha) => {
          if (filteredFields.includes(parseInt(ficha.id))) found.push("ok");
        });
        if (found.length === filteredFields.length) return product;
      });

      setProducts(resul);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTechnicalFields();
    getProducts();
    setShowMenu(true)
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getProducts();
  }, [filteredFields]);

  function deleteField(item, category = false) {
    if (category) {
      setTechnicalFields({})
      setFilterdFields({})
      setSelectedCategory("")
      history.push('/pesquisar')
    }
    else {
      const fieldsValue = technicalFields.filter((x) => x.id !== item);
      setTechnicalFields(fieldsValue);

      const filterValue = filteredFields.filter((x) => parseInt(x) !== parseInt(item));
      setFilterdFields(filterValue);
    }
  }

  //https://react-slick.neostack.com/docs/example/swipe-to-slide
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 300,
    variableWidth: true,
    swipeToSlide: true,
  };

  return (
    <div className="search-rs-div-content">
      <div className="search-rs-div-header">
        <div className="search-rs-label-header">Buscando por...</div>
        <Slider {...settings}>
          {technicalFields &&
            technicalFields.map((technicalFile) => {
              return (
                <div className={"search-rs-div-options " + (technicalFile.category ? "search-rs-div-options-category" : "")}
                  key={`tech-${technicalFile.id}`}>
                  <span className="search-rs-lbl-cards">{technicalFile.value}</span>
                  <FiX onClick={() => deleteField(technicalFile.id, technicalFile.category ? true : false)} size={20} />
                </div>
              );
            })}
        </Slider>
      </div>
      {
        loading ? <SearchResultLoading /> : products && products.length > 0 ?
          products.map((product) => {
            return (
              <div className="search-rs-div-products" key={`prod-${product.id}`}>
                <div className="search-rs-img">
                  <img className="search-rs-img" src={product.galeria[0].url} />
                </div>
                <div className="search-rs-div-product-detail">
                  <span className="search-rs-lbl-product-reference">
                    {product.referencia}
                  </span>
                  <span className="search-rs-lbl-product-title">{product.titulo}</span>
                  <button
                    type="button"
                    id={`details`}
                    className="search-rs-lbl-product-btn"
                    onClick={() => {
                      history.push(`/produtos/${product.id}`, { origin: "filter" });
                    }}
                  >
                    Detalhes
                  </button>
                </div>
              </div>
            );
          })
          :
          <div className="search-rs-lbl-product-title"
            style={{
              marginTop: "30px",
              textAlign: "center"
            }}
          >Nenhum produto encontrado</div>
      }
    </div>
  );
};

export default SearchResult;
