import { emailValidatorInvalidMessage, phoneValidatorEmptyMessage, phoneValidatorMessage } from "../../utils/defaultMessages";
import dateFnsParse from "date-fns/parse";
import { DateUtils } from "react-day-picker";

const dateFormat = "dd/MM/yyyy";

export default function validateInfo(values) {
  let errors = {};

  if (!values.purchaseDate) {
    errors.purchaseDate = "Por favor, informe a data da compra";
  }
  else {
    const dateParsed = dateFnsParse(values.purchaseDate, dateFormat, new Date(), { locale: "pt" })

    if (values.purchaseDate.length < 10)
      errors.purchaseDate = "Por favor, informe a data completa"
    else if (!DateUtils.isDate(dateParsed) || !DateUtils.isDayAfter(dateParsed, new Date("01/01/1900")))
      errors.purchaseDate = "Por favor, informe uma data válida"
    else if (dateParsed > new Date())
      errors.purchaseDate = "A data de compra não pode ser superior a data atual"
  }

  if (!values.name) {
    errors.name = "Por favor, informe o nome completo";
  }

  if (!values.phone) {
    errors.phone = phoneValidatorEmptyMessage;
  }
  else {
    values.phone = values.phone.replace(/-|_/g, "").replace(" ", "").replace("(", "").replace(")", "")
    if (values.phone.length < 10)
      errors.phone = phoneValidatorMessage;
  }

  if (!values.birthDate) {
    errors.birthDate = "Por favor, informe a data de nascimento";
  }
  else {
    const dateParsed = dateFnsParse(values.birthDate, dateFormat, new Date(), { locale: "pt" })

    if (values.birthDate.length < 10)
      errors.birthDate = "Por favor, informe a data completa"
    else if (!DateUtils.isDate(dateParsed) || !DateUtils.isDayAfter(dateParsed, new Date("01/01/1900")))
      errors.birthDate = "Por favor, informe uma data válida"
    else if (dateParsed > new Date())
      errors.birthDate = "A data de compra não pode ser superior a data atual"
  }

  if (!values.customerEmail) {
    errors.customerEmail = "E-mail do cliente é necessário";
  } else if (!/\S+@\S+\.\S+/.test(values.customerEmail)) {
    errors.customerEmail = emailValidatorInvalidMessage;
  }

  if (!values.customerType) {
    errors.customerType =
      "Por favor, informe se o cliente é Pessoa Física ou Pessoa Jurídica";
  }

  if (!values.resellerValue) {
    errors.resellerValue = "Por favor, informe o nome da revenda";
  }

  if (!values.notaFiscalKeyAccess) {
    errors.notaFiscalKeyAccess = "Por favor, informe a chave de acesso";
  }

  if (!values.notaFiscalNumber) {
    errors.notaFiscalNumber = "Por favor, informe o número da nota fiscal";
  }

  // else if (values.notaFiscalKeyAccess.length !== 44) {
  //   errors.notaFiscalKeyAccess = "Por favor, informe a chave de acesso válida";
  // }

  if (values.productsPurchased.length <= 0) {
    errors.productsPurchased = "Por favor, informe os produtos adquiridos";
  }

  return errors;
}
