import React, { } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function LoadingList() {
    return (
        <>
            {
                Array(2)
                    .fill('')
                    .map((_, i) => (
                        <SkeletonTheme key={i} color="#202020" highlightColor="#444" >
                            <Skeleton style={{ height: '20px', marginBottom: '5px' }} />
                            {
                                Array(4)
                                    .fill('')
                                    .map((_, i) => (<Skeleton key={i} style={{ height: '17px' }} />))
                            }
                        </SkeletonTheme>
                    ))
            }
        </>
    )
}


export { LoadingList }