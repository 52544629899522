import React, { useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import mapPin from "../../assets/logo-icon.png"
import { waterStyle } from './styledMap'
import './style.scss'

const containerStyle = {
  width: '100%',
  height: '50vh',
  borderRadius: '25px',
  border: '1px solid #707070',
};

const center = {
  lat: -22.8951606,
  lng: -47.1711197
};

export const GoogleMapsComponent = ({ addressList, coordinates, props }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  })

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates.lat ? coordinates : center}
      zoom={8}
      options={{
        styles: waterStyle,
        disableDefaultUI: true,
      }}
      {...props}
    >
      {
        addressList?.map((item, index) => (
          <Marker
            key={item.id}
            icon={mapPin}
            position={{ lat: item.lat, lng: item.lng }}
            onClick={() => setIsOpenDropdown(item)}
          />
        ))
      }

      {
        isOpenDropdown && (
          <InfoWindow
            position={{ lat: isOpenDropdown.lat, lng: isOpenDropdown.lng }}
            onCloseClick={() => setIsOpenDropdown(null)}
          >
            <div>
              <p className="gmap-comp-modal-lbl">
                <strong>{isOpenDropdown.nome}</strong> <br />
                {isOpenDropdown.telefone} <br />
                {isOpenDropdown.email} <br />
                {isOpenDropdown.endereco + (isOpenDropdown.numero ? ", " + isOpenDropdown.numero : "") +
                  (isOpenDropdown.bairro ? " - " + isOpenDropdown.bairro : "") + " - " +
                  `${(isOpenDropdown.cidade ? isOpenDropdown.cidade : "")} ${(isOpenDropdown.estado ? " - " + isOpenDropdown.estado : "")}`} <br />
                {isOpenDropdown.cep} <br />
                {isOpenDropdown.site}
              </p>
            </div>
          </InfoWindow>
        )
      }
    </GoogleMap>
  ) : <></>
}