import api from "../api";

async function getUserInfo(
    token
) {
    try {
        let { data } = await api.get(`/users/me`, {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        });

        const profile = await api.get(`/perfil-usuarios/${data.perfil}`)
        data.perfil_desc = profile.data.descricao

        return data
    }
    catch (error) {
        return error
    }
}

async function updateUser(token, values) {

    try {
        const { data } = await api.get(`/users/me`, {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        });

        if (values.password) {
            if (values.password === "")
                delete values.password
        }
        else
            delete values.password

        values.username = values.email //strapi default users table has a username field (like a nickname/id) and we're using email on it

        const req = await api.put(`/users/${data.id}`,
            values
            , {
                headers: {
                    Authorization:
                        `Bearer ${token}`,
                }
            });
        return req
    }
    catch (error) {
        let errorUpdated = error
        if (error.response.data.message[0].messages[0].message === 'username.alreadyTaken.') {
            errorUpdated = { error: true, message: "username.alreadyTaken" }
        }
        return errorUpdated
    }
}

export { getUserInfo, updateUser }