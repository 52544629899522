import {
  passwordValidatorMessage, nameValidatorMessage, phoneValidatorEmptyMessage, phoneValidatorMessage,
  emailValidatorEmptyMessage, emailValidatorInvalidMessage, profileValidatorMessage, passwordValidatorEmptyMessage
} from '../../utils/defaultMessages';
import passwordValidator from "../../utils/passwordValidator";

export default function validateInfo(values) {
  let errors = {};
  // let regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*()_+^&}{:;?.])(?:([0-9a-zA-Z!@#$%;*(){}_+^&])(?!\1)){6,}$/;

  if (!values.occupation && values.occupation === "") {
    errors.occupation = profileValidatorMessage;
  }

  if (!values.name) {
    errors.name = nameValidatorMessage;
  }

  if (!values.email) {
    errors.email = emailValidatorEmptyMessage;
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = emailValidatorInvalidMessage;
  }

  if (!values.password) {
    errors.password = passwordValidatorEmptyMessage;
  }

  if (!passwordValidator(values.password)) {
    errors.password = passwordValidatorMessage;
  }
  
  if (values.phone) {
    values.phone = values.phone.replace(/-|_/g, "").replace(" ", "").replace("(", "").replace(")", "")
    if (values.phone.length < 10)
    errors.phone = phoneValidatorMessage;
  } else {
    errors.phone = phoneValidatorEmptyMessage;
  }

  return errors;
}
