import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import './style.scss';

function SearchResultLoading() {
    return (
        <>
            {Array(3)
                .fill('')
                .map((_, i) => (
                    <SkeletonTheme color="#202020" highlightColor="#444">
                        <div key={i} className="search-rs-div-products">

                            <div className="search-rs-img" style={{ width: "125px" }}>
                                <Skeleton style={{ height: "125px", width: "125px" }} />
                            </div>
                            <div className="search-rs-div-product-detail">
                                <Skeleton style={{ width: "40%" }} />
                                <Skeleton style={{ width: "50%" }} />
                            </div>
                        </div>
                    </SkeletonTheme>
                ))
            }
        </>
    )
}

export default SearchResultLoading;