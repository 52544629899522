import { Redirect, Switch, BrowserRouter as Router } from "react-router-dom";
import Route from "./Route";
import React, { useEffect, useContext } from "react";

import Home from "../pages/home";
import MenuNav from "../components/menu/index";
// import MenuNovo from '../components/menu/menu'
import About from "../pages/about";
import Footer from "../components/footer/index";
import Certification from "../pages/certification";
import ExtendedWarranty from "../pages/extended-warranty";
import ProdutoDetalhe from "../components/products-details";
import Login from "../pages/login";

import "../style.scss";
import EmailRecovery from "../pages/email-recovery";
import ForgotPassword from "../pages/forgot-password";
import Cadastro from "../pages/registration";
import OndeEncontrar from "../pages/where-find";
import AssistenciaTecnica from "../pages/technical-assistance";
import ProdutosPage from "../pages/products";
import CategoriasProdutos from "../components/products-category";
import Context from "../context/context";
import Header from "../components/header";
import CategoryFilter from "../pages/category-filter";
import SearchResult from "../pages/search-result";
import ProductWarranty from "../pages/product-warranty";

import Project from "../pages/projects";
import NewProject from "../pages/projects/new-project";
import ViewProject from "../pages/projects/view-project";

import SharePdf from "../pages/share-pdf";
import UserProfile from "../pages/user-profile";
import { Profiles } from "../utils/profiles";
import api from "../services/api";

import "../style.scss";


const Routes = () => {

  const { menu, setMenu, isSigned, setIsSigned, Elettromec, showMenu, location, showFooter } =
    useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllInputs();
    async function getMenu() {
      const { data } = await api.get(`/menus`);

      let menuOrdered
      if (data && data[0].ordenacao)
        menuOrdered = data.sort((a, b) =>
          (a.ordenacao !== null ? a.ordenacao : 50) >
            (b.ordenacao !== null ? b.ordenacao : 50)
            ? 1
            : -1,
        );

      if (parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === Profiles.AssistenciaTecnica ||
        parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === Profiles.Consumidor) {

        let tempMenu = menuOrdered.filter((item) => item.rota !== "projetos");

        if (parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === Profiles.Consumidor) {
          tempMenu = tempMenu.filter((item) => item.rota !== "perfil");
        }
        setMenu(tempMenu)
      }
      else {
        setMenu(menuOrdered)
      }
    }

    if (!menu || menu.length === 0) {
      getMenu()
    }
  }, [location]);

  let getAllInputs = () => {
    let allInputs = document.querySelectorAll("input");
    for (let i = 0; i < allInputs.length; i++) {
      let allInput = allInputs[i];
      let allInputsId = allInputs[i].id;

      if (!allInputsId.startsWith("react-select")) {
        if (allInput.type !== "radio") {
          if (allInput.parentElement.className !== "search-map") {
            allInput.setAttribute("onfocus", "form.scrollIntoView({behavior: 'smooth', block: 'start'})");
          }
        }
      }
    }
  }

  const Logout = () => {
    window.localStorage.removeItem(`${Elettromec}:isSigned`);
    window.localStorage.removeItem(`${Elettromec}:token`);
    window.localStorage.removeItem(`${Elettromec}:userName`);
    window.localStorage.removeItem(`${Elettromec}:userProfile`);
    setIsSigned(false);

    // let menuData = [];
    // menuData = menuBkp.filter((item) => item.rota !== "logout");
    // setMenu([]);

    return <Redirect to="/login" />;
  };

  return (
    <Router>
      <div id="outer-container">
        <Header />
        {
          (isSigned || window.localStorage[`${Elettromec}:isSigned`] === "true") &&
          showMenu && (
            <MenuNav
              menu={menu}
              profile={window.localStorage.getItem(`${Elettromec}:userProfile`)}
            />
          )
          // <MenuNav menu={menu} />
        }
        <div id="page-wrap">
          <Switch>
            <Route path="/login" component={Login} exact />
            <Route path="/home" component={Home} exact isPrivate />
            <Route path="/email-recovery" component={EmailRecovery} exact />
            <Route path="/forgot-password" component={ForgotPassword} exact />
            <Route path="/sobre" component={About} exact isPrivate />
            <Route path="/certificacoes" component={Certification} exact isPrivate />
            <Route path="/garantia-estendida" component={ExtendedWarranty} exact isPrivate />
            <Route path="/garantia" component={ProductWarranty} exact isPrivate />
            <Route path="/produtos" component={ProdutosPage} needToken exact isPrivate />
            <Route path="/categorias" component={CategoriasProdutos} exact isPrivate />
            <Route path="/produtos/:id" component={ProdutoDetalhe} exact isPrivate />
            <Route path="/encontrar" component={OndeEncontrar} exact isPrivate />
            <Route path="/assistencia" component={AssistenciaTecnica} exact isPrivate />
            <Route path="/logout" component={Logout} exact isPrivate />
            <Route path="/pesquisar" component={CategoryFilter} exact isPrivate />
            <Route path="/resultado-busca" component={SearchResult} exact isPrivate />
            <Route path="/cadastro" component={Cadastro} exact needToken />
            <Route path="/projetos" component={Project} exact isPrivate needToken project />
            <Route path="/perfil" component={UserProfile} exact isPrivate needToken profile />
            <Route path="/projetos/:id" component={ViewProject} exact isPrivate needToken project />
            <Route path="/novo-projeto" component={NewProject} exact isPrivate needToken project />
            <Route path="/projetos/:id/pdf" component={SharePdf} exact isPrivate needToken project />
            <Redirect from="/*" to="/login" />
          </Switch>
        </div>
        {(isSigned || window.localStorage[`${Elettromec}:isSigned`] === "true") && showFooter && (
          <Footer />
        )}
      </div>
    </Router>
  );
};

export default Routes;
