import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Spinner } from "reactstrap";
import InputMask from 'react-input-mask'
import { accountCreateSuccessMessage, emailAlreadyExist } from '../../utils/defaultMessages'

import { BsChevronLeft } from "react-icons/bs";
import { FiEyeOff, FiEye } from "react-icons/fi";

import api from "../../services/api";

import useForm from "./useForm";
import validate from "./validateInfo";
import { Profiles } from "../../utils/profiles";

import "./style.scss";
import "../../style.scss"

const Cadastro = () => {
  const [userProfiles, setUserProfiles] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [phoneMask, setPhoneMask] = useState("(99) 9999-99999")

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    handleChangeSelect,
    occupations,
    hasSending,
    loading,
    errorEmail,
    setErrorEmail,
    handleError
  } = useForm(validate);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    if (values.phone) {
      const value = values.phone.replace(/-|_/g, "").replace(" ", "").replace("(", "").replace(")", "")
      console.log(value);
      if (value.length === 11)
        setPhoneMask("(99) 99999-9999")
      else
        setPhoneMask("(99) 9999-99999")
    }
    else
      setPhoneMask("(99) 9999-9999")
  }, [values.phone])

  useEffect(() => {
    window.scrollTo(0, 0);

    async function loadProfiles() {
      try {
        const res = await api.get(`/perfil-usuarios`);

        const data = res.data.map((x) => {
          const perfil = {};
          perfil.value = x.id;
          perfil.label = x.descricao;
          return perfil;
        });

        let profileResponseData = [];
        profileResponseData = data.filter((item) => item.value !== Profiles.Consumidor);

        setUserProfiles(profileResponseData);
      } catch (error) {
        console.error(error);
      }
    }

    loadProfiles();
  }, []);

  const selectStyles = {
    container: (base) => ({
      ...base,
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      borderRadius: 25,
      border: errors.occupation ? "1px solid #FF0000" : "1px solid #707070",
      boxShadow: "none",
      background: "black",
      padding: 8,
      cursor: "pointer",
      ":hover": {
        borderColor: "#707070",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      color: "white",
      height: 35,
    }),
    singleValue: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      color: "white",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      background: "transparent",
      color: "white",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 25,
      border: "1px solid #707070",
      background: "black",
      padding: 8,
    }),
    option: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      height: "100%",
      cursor: "pointer",
    }),
  };

  return (
    <div className="pg-rg-div-content">
      <div className="pg-rg-div-header">
        <button
          type="button"
          onClick={() => {
            window.history.back();
          }}
        >
          <BsChevronLeft />
        </button>
        <h3>Crie sua conta</h3>
      </div>

      <form autoComplete="no" onSubmit={handleSubmit}>
        {hasSending ? (
          <>
            <div className="pg-rg-content">
              <div style={{ height: "86px" }}></div>
              <span className="pg-rg-label-info">
                {errors.request ? errors.request : accountCreateSuccessMessage}
              </span>
              <Link to="/">
                <button type="button" block>
                  INÍCIO
                </button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <span className="general-lbl-above-inputs">Qual é sua função? *</span>
            <Select
              styles={selectStyles}
              placeholder="Selecione"
              options={userProfiles}
              autoFocus
              onFocus={(e) => handleError({ target: { ...e.target, name: "occupation" } })}
              name="occupation"
              value={occupations.label}
              onChange={handleChangeSelect}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 20,
                colors: {
                  ...theme.colors,
                  primary75: "transparent",
                  primary50: "transparent",
                  primary25: "transparent",
                  primary: "transparent",
                },
              })}
            />
            {errors.occupation && (
              <span className="general-lbl-info-message">{errors.occupation}</span>
            )}

            <span className="general-lbl-above-inputs">Nome completo *</span>
            <input
              type="text"
              name="name"
              placeholder="Digite aqui"
              value={values.name}
              onChange={handleChange}
              onFocus={(e) => {
                e.target.placeholder = ""
                handleError(e)
              }}
              onBlur={(e) => {
                e.target.placeholder = "Digite aqui"
              }}
              className="general-input"
              style={{
                border: errors.name ? "1px solid #FF0000" : "",
              }}
            />
            {errors.name && <span className="general-lbl-info-message">{errors.name}</span>}
            <br></br>
            <span className="general-lbl-above-inputs">Telefone *</span>
            <InputMask
              alwaysShowMask={false}
              mask={phoneMask}
              maskChar={null}
              value={values.phone}
              name="phone"
              placeholder="(11) 99999-9999"
              className="general-input"
              onChange={handleChange}
              style={{
                border: errors.phone ? "1px solid #FF0000" : "",
              }}
              onFocus={handleError}
            />
            {errors.phone && <span className="general-lbl-info-message">{errors.phone}<br /></span>}
            <br />

            <span className="general-lbl-above-inputs">Seu e-mail *</span>
            <input
              name="email"
              placeholder="nome@email.com.br"
              value={values.email}
              onChange={handleChange}
              onFocus={(e) => {
                handleError(e)
                setErrorEmail(false)
                e.target.placeholder = ""
              }}
              onBlur={(e) => {
                e.target.placeholder = "nome@email.com.br"
              }}
              className="general-input"
              style={{
                border: errors.email ? "1px solid #FF0000" : errorEmail ? "1px solid #FF0000" : "",
              }}
            />
            {errors.email && <span className="general-lbl-info-message">{errors.email}</span>}
            {errorEmail && <span className="general-lbl-info-message">{emailAlreadyExist}</span>}
            <br></br>

            <span className="general-lbl-above-inputs">Senha *</span>
            <div className="pg-fg-pw-div-inputs">
              <input
                type={passwordShown ? "text" : "password"}
                name="password"
                autoComplete="senha"
                value={values.password}
                onChange={handleChange}
                className="general-input"
                onFocus={(e) => {
                  e.target.placeholder = ""
                  handleError(e)
                }}
                onBlur={(e) => {
                  e.target.placeholder = "●●●●●●"
                }}
                placeholder="●●●●●●"
                style={{
                  border: errors.password ? "1px solid #FF0000" : "",
                }}
              />
              <i onClick={togglePasswordVisibility}>
                {passwordShown ? <FiEye size="23" /> : <FiEyeOff size="23" />}
              </i>
            </div>
            {errors.password && (
              <span className="general-lbl-info-message">{errors.password}</span>
            )}
            {loading ? (
              <div className="pg-rg-svg-spinner">
                <Spinner color="light" size={'20'} />
              </div>
            ) : (
              <button type="submit" block>
                CRIAR CONTA
              </button>
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default Cadastro;
