import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete'
import { FaTimes } from 'react-icons/fa'

import './style.scss'

export const PlacesAutocompleteComponent = ({ address, setAddress, handleSelect, clearValidator }) => {

  var options = {
    types: ['(cities)'],
    componentRestrictions: { country: "br" }
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search-map">
            <input {...getInputProps({ placeholder: 'Pesquise um local...' })} onFocus={(e) => {
              e.target.placeholder = ""
              clearValidator()
            }}
              onBlur={(e) => e.target.placeholder = "Pesquise um local..."} />
            {address && (
              <FaTimes
                className="search-map-close"
                onClick={() => {
                  setAddress('')
                  clearValidator()
                }
                }
              />
            )}

            <div className={(loading || suggestions?.length > 0) ? "search-map-results" : ""}>
              {loading && <div className="search-map-loading">Carregando<hr /></div>}

              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? "#ffffff" : "#333333",
                  color: suggestion.active ? "#000000" : "#ffffff",
                  margin: "10px 0",
                  padding: "0 5px",
                  borderRadius: "5px"
                }

                return (
                  <div key={index} {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}
