import React, { useState, useEffect, useContext } from "react";

import { FiEye, FiEyeOff } from 'react-icons/fi'
import InputMask from 'react-input-mask'

import validateInfo from "./validateInfo";
import { getUserInfo, updateUser } from "../../services/users";
import Context from "../../context/context";

import './style.scss'
import '../../style.scss'
import { Spinner } from "reactstrap";
import { emailAlreadyExist, generalSaveError } from "../../utils/defaultMessages";

const UserProfile = ({ ...props }) => {

  const { Elettromec } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false)
  const [phoneMask, setPhoneMask] = useState("(99) 9999-99999")
  const [errors, setErrors] = useState({ name: "", email: "", password: "", phone: "" })
  const [values, setValues] = useState({ name: "", email: "", password: "", phone: "", profile: "" })
  const [backupValues, setBackupValues] = useState({ name: "", email: "", password: "", phone: "", profile: "" })
  const [loading, setLoading] = useState(false)
  const [textSaveInfo, setTextSaveInfo] = useState("")

  useEffect(() => {
    getInfoPage()
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setTextSaveInfo("")
        clearTimeout()
      }, 3000)
    }
  }, [loading]);

  useEffect(() => {
    if (values.phone) {
      const value = values.phone.replace(/-|_/g, "").replace(" ", "").replace("(", "").replace(")", "")
      if (value.length === 11)
        setPhoneMask("(99) 99999-9999")
      else
        setPhoneMask("(99) 9999-99999")
    }
    else
      setPhoneMask("(99) 9999-9999")
  }, [values.phone])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleError = (e) => {
    const { name } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  async function submit(event) {
    try {
      event.preventDefault()
      const erros = validateInfo(values)
      setErrors(erros)
      if (erros.error)
        return

      setLoading(true)
      let data = await updateUser(window.localStorage[`${Elettromec}:token`], {
        name: values.name,
        phone: values.phone,
        email: values.email,
        password: values.password
      })

      if (data.error) {
        throw data
      }
      else
        data = data.data

      const newValues = { name: data.name, phone: data.phone, email: data.email, profile: data.perfil.descricao }

      setValues(newValues)
      setBackupValues(newValues)

      setTextSaveInfo("Salvo com sucesso")
      setLoading(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } catch (error) {
      if (error.message === 'username.alreadyTaken')
        setTextSaveInfo(emailAlreadyExist)
      else
        setTextSaveInfo(generalSaveError)
      setLoading(false)
    }
  }

  const getInfoPage = async () => {
    try {
      const { name, phone, email, perfil_desc } = await getUserInfo(window.localStorage[`${Elettromec}:token`])
      setValues({ name, phone, email, profile: perfil_desc })
      setBackupValues({ name, phone, email, profile: perfil_desc })
    } catch (error) {

    }
  };

  const objectsEqual = (o1, o2) => {
    return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => o1[p] === o2[p]);
  }

  return (
    <div className="pg-user-prof-div-content">
      <div className="project-div-header">
        <span className="usprof-lbl-title-first">Minha</span>
        <span className="usprof-lbl-title-last">Conta</span>
      </div>
      <form autoComplete="no" onSubmit={submit}>
        <div><span className="pg-usprof-lbl-function" >{values.profile}</span><br /></div>
        <span>Nome completo</span>
        <input
          maskChar={'-'}
          type="text"
          name="name"
          placeholder="Digite aqui"
          value={values.name}
          onChange={handleChange}
          onFocus={(e) => {
            e.target.placeholder = ""
            handleError(e)
          }}
          onBlur={(e) => {
            e.target.placeholder = "Digite aqui"
          }}
          className="pg-rg-input"
          style={{
            border: errors.name ? "1px solid #FF0000" : "",
          }}
        />
        {errors.name && <span className="general-lbl-info-message">{errors.name}<br /></span>}
        <span className="general-lbl-above-inputs">Telefone</span>
        <InputMask
          alwaysShowMask={false}
          mask={phoneMask}
          maskChar={null}
          value={values.phone}
          name="phone"
          placeholder="(11) 99999-9999"
          className="general-input"
          onChange={handleChange}
          onFocus={(e) => handleError(e)}
          style={{
            border: errors.phone ? "1px solid #FF0000" : "",
          }}
        />
        {errors.phone && <span className="general-lbl-info-message">{errors.phone}<br /></span>}
        <span className="general-lbl-above-inputs">Seu e-mail</span>
        <input
          maxLength={256}
          name="email"
          placeholder="nome@email.com.br"
          className="general-input"
          onChange={handleChange}
          onFocus={(e) => {
            e.target.placeholder = ""
            handleError(e)
          }}
          onBlur={(e) => {
            e.target.placeholder = "nome@email.com.br"
          }}
          value={values.email}
          style={{
            border: errors.email ? "1px solid #FF0000" : ""
          }}
        />
        {errors.email && <span className="general-lbl-info-message">{errors.email}<br /></span>}
        {false && <span className="general-lbl-info-message">Este e-mail ja está cadastrado.<br /></span>}

        <span className="general-lbl-above-inputs">Senha</span>
        <div className="pg-fg-pw-div-inputs">
          <input
            maxLength={256}
            type={showPassword ? "text" : "password"}
            name="password"
            autoComplete="senha"
            value={values.password}
            onChange={handleChange}
            onFocus={(e) => {
              handleError(e)
            }}
            className="pg-rg-input"
            style={{
              border: errors.password ? "1px solid #FF0000" : ""
            }}
          />
          <i onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FiEye size="23" /> : <FiEyeOff size="23" />}
          </i>
        </div>
        {errors.password && (<span className="general-lbl-info-message">{errors.password}</span>)}
        {textSaveInfo ? <span className="general-lbl-info-message">{textSaveInfo}</span> : <br />}
        <div className="pg-usprof-div-save">
          {
            loading ? <Spinner color="light" /> :
              <button type="submit" className="general-btn" disabled={objectsEqual(values, backupValues)}>SALVAR</button>
          }
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
