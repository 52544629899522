import { useState, useEffect } from "react";
import { sendRequestRegistration } from "../../services/register";
const useForm = (validate) => {
  const [values, setValues] = useState({
    occupation: "",
    name: "",
    email: "",
    password: "",
    phone: "",
  });

  const [occupations, setOccupation] = useState("");

  const [errors, setErrors] = useState({});
  const [hasSending, setHasSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false)


  async function sendRequest() {
    const request = await sendRequestRegistration(
      occupations,
      values.name,
      values.email,
      values.password,
      values.phone
    );

    if (request.data && request.data.message[0].messages[0].message === 'Email is already taken.') {
      setLoading(false)
      setErrorEmail(true)
    } else if (request === 200) {
      setHasSending(true);
      setLoading(false);
    } else {
      setHasSending(true);
      setLoading(false);
      setErrors({
        request:
          <p style={{ textAlign: 'center', fontSize: '13px' }}>
            Infelizmente não foi possível criar sua conta <br /> Verifique novamente mais tarde
          </p>,
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeSelect = (e) => {
    setOccupation(e.value);
  };

  useEffect(() => {
    setValues({
      ...values,
      occupation: occupations,
    });
  }, [occupations]);

  useEffect(() => { }, [values]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setLoading(true);
      sendRequest();
    }
  }, [errors]);

  const handleError = (e) => {
    const { name } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  return {
    handleChange,
    values,
    errors,
    handleSubmit,
    handleChangeSelect,
    occupations,
    hasSending,
    setOccupation,
    errorEmail,
    setErrorEmail,
    handleError,
    loading
  };
};

export default useForm;
