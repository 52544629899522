import { useState, useEffect } from "react";
import { sendRequestProductWarranty } from "../../services/product-warranty";
const useForm = (validate) => {
  const [values, setValues] = useState({
    purchaseDate: "",
    name: "",
    phone: "",
    birthDate: "",
    customerEmail: "",
    customerType: "",
    resellerValue: "",
    resellerData: [],
    notaFiscalKeyAccess: "",
    notaFiscalNumber: "",
    productsPurchased: [],
    productsInformation: []
  });
  const [purchaseDate, setPurchaseDate] = useState("");
  const [productsPurchased, setProductsPurchased] = useState([]);
  const [reseller, setReseller] = useState();
  const [errors, setErrors] = useState({});
  const [hasSending, setHasSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successPage, setSuccessPage] = useState(false)

  async function sendRequest() {
    const request = await sendRequestProductWarranty(values);

    if (request === 200) {
      setHasSending(true);
      setLoading(false);
      setValues({
        purchaseDate: "",
        name: "",
        birthDate: "",
        phone: "",
        customerEmail: "",
        customerType: "",
        resellerValue: "",
        resellerData: [],
        resellerEmail: "",
        notaFiscalKeyAccess: "",
        notaFiscalNumber: "",
        productsPurchased: [],
        productsInformation: []
      });
      setSuccessPage(true)
      window.scrollTo(0, 0)
    } else {
      setHasSending(true);
      setLoading(false);
      setErrors({
        request: "Erro ao criar sua conta, tente novamente mais tarde",
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeDatePicker = (e) => {
    setPurchaseDate(e);
  };

  const handleChangeSelect = (e) => {
    if (e && e.length > 0) {
      const newValue = e.map((item) => item)
      setProductsPurchased(newValue);
    }
    else
      setProductsPurchased([]);
  };

  const handleSelectResellerChange = (e) => {
    if (e && e.value)
      setReseller(e)
    else
      setReseller("")
  }

  useEffect(() => {
    setValues({
      ...values,
      purchaseDate: purchaseDate,
    });
  }, [purchaseDate]);

  useEffect(() => {
    setValues({
      ...values,
      productsPurchased: productsPurchased.map(x => x.value),
      productsInformation: productsPurchased
    });
  }, [productsPurchased]);

  useEffect(() => {
    setValues({
      ...values,
      resellerValue: reseller?.value,
      resellerEmail: reseller?.email,
      resellerData: reseller
    });
  }, [reseller]);

  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     notaFiscalKeyAccess: formatarChaveDeAcessoNfe(values.notaFiscalKeyAccess),
  //   });
  // }, [values.notaFiscalKeyAccess]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleError = (e, value = "") => {
    const { name } = e.target;
    setErrors({
      ...errors,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setLoading(true);
      sendRequest();
    }
  }, [errors]);

  return {
    handleChange,
    values,
    productsPurchased,
    setProductsPurchased,
    errors,
    handleSubmit,
    handleChangeDatePicker,
    handleChangeSelect,
    handleSelectResellerChange,
    purchaseDate,
    hasSending,
    setHasSending,
    setPurchaseDate,
    loading,
    successPage,
    setSuccessPage,
    handleError
  };
};

export default useForm;
