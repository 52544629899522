import React, { useState, useEffect } from "react";

import { getInfoPage } from "../../services/index";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { simpleText2br } from "../../utils";

import './style.scss'

const Sobre = ({ ...props }) => {
  const [pageInfo, setPageInfo] = useState({});
  const location = useLocation().pathname.replace("/", "");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadApi(location) {
      setLoading(true)
      const data = await getInfoPage(location)
      if (data)
        setPageInfo(data)
      else
        setPageInfo({})
      setLoading(false)
    }
    loadApi(location)
  }, [location])

  return (
    <>
      <div className="pg-about-div-content">
        <div className="pg-about-header">
          <span className="pg-about-lbl-about">Sobre</span>
        </div>
        <div className="pg-about-description">
          {
            !loading ? <p>{simpleText2br(pageInfo?.descricao)}</p>
              :
              <SkeletonTheme color="#202020" highlightColor="#444">
                <Skeleton style={{ height: '350px' }} />
              </SkeletonTheme>
          }
        </div>
      </div>
    </>
  );
};

export default Sobre;
