import React, { useState, useEffect, useContext } from 'react'
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import api from '../../services/api'
import { Link as Anchor } from "react-scroll";
import Context from "../../context/context";
import { Profiles } from "../../utils/profiles";
import ProjectList from "../projects";
import { simpleText2br } from '../../utils';

import './style.scss'

const ProdutosCarousel = ({ categoria }) => {

  let history = useHistory();

  const [produtos, setProdutos] = useState([]);
  const [idProduct, setIdProduct] = useState('')
  const [productTitle, setProductTitle] = useState('')

  const { Elettromec, setShowMenuProject, showMenuProject } = useContext(Context);

  useEffect(() => {

    async function loadApi() {
      try {
        if (categoria) {
          const { data } = await api.get(`/categorias/${categoria}`)

          setProdutos(data.produtos)
        }
      } catch (error) {
        console.error(error);
      }
    }

    loadApi();
    window.scrollTo(0, 0)
  }, [categoria]);



  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    //if (animating) return;
    const nextIndex = activeIndex === produtos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    //if (animating) return;
    const nextIndex = activeIndex === 0 ? produtos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const ride = false;

  const slides = produtos.map((item) => {

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}>
        <div className="pr-ca-carousel-content">
          <Anchor to={`details${item.id}`}
            spy={true}
            smooth={true}
            offset={-600}
            duration={1200}
            style={{textAlign: "center"}}
          >
            <img className="pr-ca-img-content" src={`${item.galeria[0].url}`} alt={item.hash} />
          </Anchor>
          <div className="pr-ca-lbl-reference">{`${item.referencia}`}</div>

          <div>
            <div className="pr-ca-lbl-product-title">
              {item.titulo}<br />
            </div>
          </div>

          <div className="pr-ca-lbl-product-description">
            {simpleText2br(item.descricao)}
          </div>
          {
            item.medidas &&
            <div className="pr-ca-lbl-sizes">
              Medidas: {item.medidas}
            </div>
          }
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div className="pr-ca-div-details"><button id={`details${item.id}`} className="pr-ca-lnk-details" onClick={() => { history.push(`/produtos/${item.id}`)}}>Detalhes</button></div>
            {
              parseInt(window.localStorage[`${Elettromec}:userProfile`]) !== Profiles.Consumidor && parseInt(window.localStorage[`${Elettromec}:userProfile`]) !== Profiles.AssistenciaTecnica &&
              <div className="pr-ca-div-details"><button onClick={() => {
                setShowMenuProject(true)
                // getProduct()
                setIdProduct(item.id)
                setProductTitle(item.titulo)
              }
              } className="pr-ca-lnk-details">{'Adicionar ao projeto'}</button></div>
            }
          </div>
        </div>
      </CarouselItem>
    );
  }
  );

  return (
    <div className="pr-ca-body">
      <Carousel
        interval={ride}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators className="pr-ca-indicator" items={produtos} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
      </Carousel>
      {showMenuProject && <ProjectList productId={idProduct && idProduct} productName={productTitle && productTitle} />}
    </div>
  );

}

export default ProdutosCarousel;