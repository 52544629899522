import axios from "axios";
import { HOST } from '../api'

async function sendRequestRegistration(
  perfil,
  name,
  email,
  password,
  cellphone
) {
  try {
    const response = await axios.post(
      `${HOST}/auth/local/register`,
      {
        perfil: perfil,
        username: email,
        name,
        email,
        password,
        cellphone
      },
    );
    return response.status;
  } catch (error) {
    return error.response;
  }
}

export { sendRequestRegistration };
