import { useState, useEffect } from "react";
import { sendRequestForgotPassword } from "../../services/forgot-password";

const useForm = (validate) => {
  const [values, setValues] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [hasSending, setHasSending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasEmail, setHasEmail] = useState("");
  const [loading, setLoading] = useState(false)

  async function sendRequest() {
    setLoading(true)
    const request = await sendRequestForgotPassword(
      hasEmail,
    );

    if (request === 200) {
      setHasSending(true);
    }
    else {
      let message = "Erro ao enviar e-mail de recuperação. Por favor, tente novamente mais tarde"
      if (request.data?.message[0]?.messages[0]?.id === "Auth.form.error.user.not-exist")
        message = "Por favor, verifique o e-mail informado"

      handleError({ target: { name: "email" } }, message)
    }
    setLoading(false)
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleError = (e, value = "") => {
    const { name } = e.target;
    setErrors({
      ...errors,
      [name]: value,
    });
  };

  const handleSubmit = (e, email) => {
    e.preventDefault();

    setHasEmail(email);
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      sendRequest();
    }
  }, [errors]);

  return {
    handleChange,
    values,
    errors,
    handleSubmit,
    hasSending,
    handleError,
    loading
  };
};

export default useForm;
