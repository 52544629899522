import React from "react";
import { Link, useHistory } from "react-router-dom";
import useForm from "./useForm";
import validate from "./validateInfo";
import { Spinner } from "reactstrap";

import { BsChevronLeft } from "react-icons/bs";

import "./style.scss";

const EmailRecoveryPage = () => {
  const history = useHistory();
  const { handleChange, values, errors, handleSubmit, hasSending, handleError, loading } = useForm(validate);

  const goBack = () => window.history.back();
  const goHome = () => history.push("/");

  return (
    <>
      <div className="pg-emailrec-div-content">
        <div className="pg-emailrec-div-header">
          <button type="button" onClick={() => goBack()}>
            <BsChevronLeft />
          </button>
          <h3>REDEFINIÇÃO DE SENHA</h3>
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(e, values.email);
          }}
        >
          {hasSending ? (
            <>
              <span className="label-response">E-mail enviado com sucesso</span>
              <button type="button" block onClick={goHome}>
                INÍCIO
              </button>
            </>
          ) : (
            <>
              <span>E-mail</span>
              <input
                name="email"
                placeholder="nome@email.com.br"
                value={values.email}
                onChange={handleChange}
                onFocus={(e) => {
                  handleError(e)
                  e.target.placeholder = ""
                }}
                onBlur={(e) => e.target.placeholder = "nome@email.com.br"}
                className="pg-emailrec-input"
                style={{
                  border: errors.email ? "1px solid #FF0000" : "",
                }}
              />
              {errors.email && (
                <span className="pg-emailrec-label-info">{errors.email}</span>
              )}

              {
                loading ? <Spinner className="pg-email-rec-svg-load" color="light" size={20} />
                  : <button type="submit" block>
                    ENVIAR
                  </button>
              }
            </>
          )}
        </form>

        <div className="pg-lg-div-signup">
          <Link to="/cadastro">Ainda não tem um cadastro?</Link>
        </div>
      </div>
    </>
  );
};

export default EmailRecoveryPage;
