import { StyleSheet, Font } from '@react-pdf/renderer';

import BrisaRegular from '../../fonts/BrisaRegular.ttf'
import JosefinSansRegular from '../../fonts/JosefinSans-Regular.ttf'
import JosefinSansLight from '../../fonts/JosefinSans-Light.ttf'

Font.register({
  family: 'BrisaRegular',
  src: BrisaRegular
})

Font.register({
  family: 'JosefinSansRegular',
  src: JosefinSansRegular
})

Font.register({
  family: 'JosefinSansLight',
  src: JosefinSansLight
})

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#000000',
    color: '#ffffff',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  logo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: -20,
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #333333',
  },
  divContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: '#fff',
  },
  divHeader: {
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'middle',
    width: '100%',
    marginTop: '20px',
    padding: '5px',
  },
  divHeaderTitle: {
    marginTop: '-20px',
    color: '#ffffff',
    fontSize: 90,
    fontFamily: 'BrisaRegular'
  },
  divProject: {
    display: 'flex',
    flexDirection: 'row',
  },
  divListInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    margin: '5px 0',
    padding: '10px 0',
  },
  lblProjectTitle: {
    fontFamily: 'JosefinSansRegular',
    fontSize: 22,
    letterSpacing: '2.4px',
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  lblProducts: {
    fontFamily: 'JosefinSansLight',
    fontSize: 18,
    letterSpacing: '1.92px',
    color: '#ffffff',
    marginTop: '10px',
  },
  line: {
    borderBottom: '1px solid #333333',
  },
  divListProduct: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '5px 0',
    padding: '10px 0',
  },
  listImg: {
    width: '33%',
  },
  listItem: {
    width: '67%',
    paddingLeft: '50px',
  },
  listItemRef: {
    display: 'block',
    fontFamily: 'JosefinSansLight',
    fontSize: 11,
    letterSpacing: '1.08px',
    color: '#ffffff',
  },
  listItemTitle: {
    display: 'block',
    height: 'auto',
    fontFamily: 'JosefinSansRegular',
    fontSize: 13,
    letterSpacing: '1.32px',
    color: '#ffffff',
  },
  listItemDescription: {
    margin: '20px 0',
    fontFamily: 'JosefinSansRegular',
    fontSize: 13,
    textAlign: 'justify'
  },
  listeItemTable: {
    width: '100%',
    fontFamily: 'JosefinSansRegular',
    fontSize: 11,
  },
  listItemTableTr: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '-1px',
  },
  listItemTableTh: {
    border: '1px solid #707070',
    padding: '5px 10px',
    width: '33%'
  },
  listItemTableTd: {
    border: '1px solid #707070',
    padding: '5px 10px',
    width: '67%',
    marginLeft: '-1px',
  },
  listItemDownloadTdInner: {
    width: '100%'
  },
  listItemDownloadLink: {
    marginRight: 10,
    color: '#ffffff',
    textDecorationColor: '#000000',
  },
  listItemDownloadLinkIcon: {
    marginRight: 5,
  },
  contact: {
    paddingTop: 100,
    textAlign: 'center',
    fontFamily: 'JosefinSansLight',
    fontSize: 13
  },
  contactTitle: {
    fontSize: 15,
    fontFamily: 'JosefinSansRegular',
    letterSpacing: '1.92px',
    color: '#ffffff',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'JosefinSansLight',
    fontSize: 13,
    color: '#ffffff',
    textDecorationColor: '#000000',
    paddingTop: 15,
  },
  contactSocial: {
    width: '100%',
    padding: 15,
  },
  contactSocialTitle: {
    fontSize: 15,
    fontFamily: 'JosefinSansRegular',
    letterSpacing: '1.92px',
    color: '#ffffff',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  contactSocialContent: {
    textAlign: 'center',
    marginBottom: 10,
  },
  contactSocialItemLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: 11,
    textDecorationColor: '#000000',
  },
  contactSocialItemLinkIcon: {
    width: '33px',
    height: '23px',
  },
  footer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'JosefinSansLight',
    fontSize: 11,
    bottom: 30,
    left: 40,
    right: 30,
    color: '#ffffff',
    paddingTop: 10,
    borderTop: '1px solid #333333',
  },
  footerIconPhone: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  footerIconEmail: {
    width: 16,
    height: 12,
    marginRight: 5,
  },
  footerPhone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '25%',
    textAlign: 'left',
  },
  footerEmail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  footerPageNumber: {
    width: '25%',
    textAlign: 'right',
  },
});