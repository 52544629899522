import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import './style.scss'

const VideoModal = (props) => {


    return (
        props && <>
            <Modal centered isOpen={props.open} toggle={props.toggle} contentClassName="comp-confirmation-content">
                <ModalHeader className="comp-confirmation-header" toggle={props.toggle}>{props.title}</ModalHeader>
                <ModalBody className="comp-confirmation-body">
                    <div className="comp-video-iframe-container">
                        <iframe 
                            tagName={props.hash} 
                            className="comp-video-iframe-yt" 
                            src={props.url} title="YouTube"
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                            mozallowfullscreen="mozallowfullscreen" 
                            msallowfullscreen="msallowfullscreen" 
                            oallowfullscreen="oallowfullscreen" 
                            webkitallowfullscreen="webkitallowfullscreen"
                        >
                        </iframe>
                    </div>
                </ModalBody>
                <ModalFooter className="comp-confirmation-footer"></ModalFooter>
            </Modal>
        </>
    );
}

export default VideoModal