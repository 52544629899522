import React, { useState, useEffect, useContext } from 'react'
import api from '../../services/api'
import { useLocation, useHistory } from 'react-router-dom'
import { BsChevronDoubleDown } from 'react-icons/bs'
import CategoriasProdutos from '../../components/products-category/index';
import { Link } from "react-scroll";
import Context from '../../context/context';

import './style.scss'

const Home = ({ ...props }) => {

  const location = useLocation().pathname
  const history = useHistory()
  const [dados, setDados] = useState([]);
  const [image, setImage] = useState([]);
  const { setShowMenu } = useContext(Context);

  useEffect(() => {

    setShowMenu(true)

    async function loadApi() {
      try {
        const res = await api.get(`/paginas`)
        const home = res.data.find(search => search.link === location.replace('/', ''))
        const imageUrl = home.imagem.url

        if (!home)
          throw "Dados não encontrados"
        setDados(home)
        setImage(imageUrl)
      } catch (error) {
        console.error(error);
      }
    }

    if (history.action === 'POP') {
      window.scrollTo(0, 700)
    }
    else
      window.scrollTo(0, 0)

    loadApi();


  }, []);

  return (
    <>
      <div id="sessao1" className="pg-home-div-content">
        <div
        style={{
          backgroundImage: dados.overlay ? "radial-gradient(rgba(0, 0, 0, 0.8) 100%, black 0%, rgba(0, 0, 0, 0.9) 80%)" : "none",  
          position: "relative",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          width: "100vw",
          height: "90vh",
          margin: "0 auto",
          zIndex: 1,
        }}
        >
        {dados.titulo &&
          <span className="pg-home-lbl-title TransitionFadeInRight">{dados.titulo}</span>
        }
        {dados.titulo_destaque &&
          <span className="pg-home-lbl-title-highlight TransitionFadeInLeft">{dados.titulo_destaque}</span>
        }
        {dados.titulo_hashtag &&
          <span className="pg-home-lbl-title-hashtag TransitionFadeInUp">{dados.titulo_hashtag}</span>
        }
        <div className="pg-home-div-footer">
          <Link to={"sessao2"}
            spy={true}
            smooth={true}
            offset={-90}
            duration={1800}
            className="scroll-down"
          >
            <BsChevronDoubleDown fill="#fff" stroke="#fff" strokeWidth="0.5" size={35} />
          </Link>
        </div>
        </div>
        <div
        style={{
          backgroundColor: '#000000',
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 0',
          backgroundSize: "auto auto",
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 0,
        }}
        className="AnimatedBackground"
        ></div>
      </div>
      <div id="sessao2"><CategoriasProdutos /></div>
    </>
  )
}

export default Home;