import { passwordValidatorMessage, nameValidatorMessage, phoneValidatorMessage, emailValidatorEmptyMessage, emailValidatorInvalidMessage, phoneValidatorEmptyMessage } from '../../utils/defaultMessages';
import passwordValidator from '../../utils/passwordValidator'

export default function validateInfo(values) {
    let errors = {};

    if (values.password && !passwordValidator(values.password)) {
        errors.password = passwordValidatorMessage;
    }

    if (!values.name) {
        errors.name = nameValidatorMessage;
    }

    if (!values.email) {
        errors.email = emailValidatorEmptyMessage;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = emailValidatorInvalidMessage;
    }

    if (values.phone) {
        values.phone = values.phone.replace(/-|_/g, "").replace(" ", "").replace("(", "").replace(")", "")
        if (values.phone.length < 10)
            errors.phone = phoneValidatorMessage;
    } else {
        errors.phone = phoneValidatorEmptyMessage;
    }

    if (errors.password || errors.name || errors.email || errors.phone)
        errors.error = true

    return errors;
}
