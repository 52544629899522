import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import api from "./services/api";
// import Routes from './routes';
import Context from "./context/context";
import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

require('dotenv').config()

function App() {
  const Elettromec = "@Elettromec";
  const [menuBkp, setMenuBkp] = useState([]);
  const [menu, setMenu] = useState([]);
  const [isSigned, setIsSigned] = useState(window.localStorage[`${Elettromec}:isSigned`]);
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [location, setLocation] = useState("");
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [filteredFields, setFilterdFields] = useState({});
  const [showMenuProject, setShowMenuProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});

  useEffect(() => {
    async function getMenu() {
      try {
        const { data } = await api.get(`/menus`);
        const apiCategories = await api.get(`/categorias`);

        const orderedCategories = apiCategories.data.sort((a, b) => {
          var titleOne = a.Titulo.toUpperCase();
          var titleTwo = b.Titulo.toUpperCase();
          return titleOne < titleTwo ? -1 : titleOne > titleTwo ? 1 : 0;
        });

        const editedCategories = orderedCategories.map((x) => {
          const categoria = {};
          categoria.value = x.id;
          categoria.label = x.Titulo;
          return categoria;
        });

        let tempMenu;

        if (parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === 4)
          //Consumidor
          tempMenu = data.filter((item) => item.rota !== "logout-consumidor");
        else
          tempMenu = data.filter(
            (item) => item.rota !== "login" && item.rota !== "logout-consumidor",
          );

        if (data && data[0].ordenacao)
          tempMenu = tempMenu.sort((a, b) =>
            (a.ordenacao !== null ? a.ordenacao : 50) >
              (b.ordenacao !== null ? b.ordenacao : 50)
              ? 1
              : -1,
          );

        setCategoriesFilter(editedCategories);
        setMenuBkp(tempMenu);

        if (!window.localStorage[`${Elettromec}:wasAccessed`]) {
          window.localStorage.setItem(`${Elettromec}:wasAccessed`, true);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getMenu();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Context.Provider
      value={{
        menu,
        setMenu,
        menuBkp,
        isSigned,
        setIsSigned,
        Elettromec,
        showHeader,
        setShowHeader,
        showFooter,
        setShowFooter,
        showMenu,
        setShowMenu,
        categoriesFilter,
        selectedCategory,
        setSelectedCategory,
        location,
        setLocation,
        filteredFields,
        setFilterdFields,
        searchMenuOpen,
        setSearchMenuOpen,
        showAllCategories,
        setShowAllCategories,
        showMenuProject,
        setShowMenuProject,
        selectedProject,
        setSelectedProject,
      }}
    >
      <div className="pg-lg-div-animate-intro">
        <div className="pg-lg-div-animate-intro-logo" />
      </div>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
