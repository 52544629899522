import jwt_decode from "jwt-decode";

export function tokenIsValid(token) {
    if (!token) {
        return false
    }
    var { exp } = jwt_decode(token)
    var dateNow = new Date();

    exp = exp * 1000 //chegar na data de hoje

    if (exp < dateNow.getTime())
        return false

    return true
}