import React, { useEffect, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Context from '../context/context';
import { tokenIsValid } from '../utils/token';
import { Profiles } from '../utils/profiles'

export default function RouteWrapper({
    component: Component,
    isPrivate,
    needToken,
    project,
    profile,
    ...rest
}) {

    const { Elettromec, setIsSigned } = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    if (window.localStorage[`${Elettromec}:isSigned`] !== 'true' && isPrivate) {
        return <Redirect to="/login" />
    }

    if (window.localStorage[`${Elettromec}:isSigned`] === 'true' && !isPrivate) {
        return <Redirect to="/home" />
    }

    if (window.localStorage[`${Elettromec}:isSigned`] === 'true' &&
        parseInt(window.localStorage[`${Elettromec}:userProfile`]) !== Profiles.Consumidor) {
        if (!tokenIsValid(window.localStorage[`${Elettromec}:token`])) {
            window.localStorage.removeItem(`${Elettromec}:isSigned`);
            window.localStorage.removeItem(`${Elettromec}:token`);
            window.localStorage.removeItem(`${Elettromec}:userName`);
            window.localStorage.removeItem(`${Elettromec}:userProfile`);
            setIsSigned(false);

            return <Redirect to="/login" />;
        }
    }

    if ((parseInt(window.localStorage[`${Elettromec}:userProfile`]) === Profiles.Consumidor ||
        parseInt(window.localStorage[`${Elettromec}:userProfile`]) === Profiles.AssistenciaTecnica) && project) {

        return <Redirect to="/home" />;
    }

    if (parseInt(window.localStorage[`${Elettromec}:userProfile`]) === Profiles.Consumidor && profile) {

        return <Redirect to="/home" />;
    }


    return (
        <Route
            {...rest}
            render={
                props => (
                    <Component {...props} />
                )
            }
        />
    )
}