import React, { useEffect, useState } from "react";
import { HOST } from "../../services/api";
import { simpleText2br } from "../../utils";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FiTrash2, FiPlus, FiMinus } from 'react-icons/fi'
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import "react-day-picker/lib/style.css";
import InputMask from 'react-input-mask'
import useForm from "./useForm";
import validate from "./validateInfo";
import { SuccessScreen } from '../../components/success-screen'
import { getInfoPage } from "../../services";
import ConfirmDialog from "../../components/shared/confirmation";


import "./style.scss";
import "../../style.scss"

const ProductWarranty = () => {

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setHasSending,
    hasSending,
    loading,
    successPage,
    handleError,
    handleSelectResellerChange,
    setProductsPurchased,
    productsPurchased
  } = useForm(validate);

  const [pageInfo, setPageInfo] = useState();
  const [codeProducts, setCodeProducts] = useState({});
  const [resellers, setResellers] = useState([])
  const [phoneMask, setPhoneMask] = useState("(99) 9999-99999")
  const [product, setProduct] = useState()
  const [productToExclude, setProductToExclude] = useState({})
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const placeholder = new Date().toLocaleDateString('pt-br')
  const dateMask = "99/99/9999"

  const location = useLocation().pathname.replace("/", "");

  const baseSelectStyles = {
    container: (base) => ({
      ...base,
      width: "100%",
    }),
    input: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    valueContainer: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      color: "#ffffff",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      background: "transparent",
      color: "white",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 25,
      border: "1px solid #707070",
      background: "black",
      padding: 8,
    }),
    option: (base) => ({
      ...base,
      font: "normal normal 300 16px/22px Josefin Sans",
      background: "transparent",
      height: "100%",
      cursor: "pointer",
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#333",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  useEffect(() => {
    getData()
    getCodeProduct();
    getResellers();

  }, []);

  async function getData() {
    const data = await getInfoPage(location);
    setPageInfo(data)
  }

  useEffect(() => {
    if (values.phone) {
      const value = values.phone.replace(/-|_/g, "").replace(" ", "").replace("(", "").replace(")", "")
      if (value.length === 11)
        setPhoneMask("(99) 99999-9999")
      else
        setPhoneMask("(99) 9999-99999")
    }
    else
      setPhoneMask("(99) 9999-9999")
  }, [values.phone])

  useEffect(() => {
    setTimeout(() => {
      setHasSending(false);
    }, 2000);
  }, [hasSending]);

  async function getCodeProduct() {
    try {
      const client = new ApolloClient({
        uri: `${HOST}/graphql`,
        cache: new InMemoryCache(),
      });
      let { data } = await client.query({
        query: gql`
          query fichaTecnica {
            produtos {
              titulo
              descricao
              referencia
              id
            }
          }
        `,
      });
      setCodeProducts(
        data.produtos.map((code) => ({ value: code.id, label: code.referencia, titulo: code.titulo })),
      );
    } catch { }
  }

  function handleChangeProductQuantity(index, value, type = 1) {
    if (!value)
      value = 0
    const newValue = productsPurchased.slice()
    if (type === 1)
      newValue[index].quantidade += parseInt(value)
    else
      newValue[index].quantidade = parseInt(value)
    if (newValue[index].quantidade < 1)
      newValue[index].quantidade = 1
    handleChange({ target: { name: "productsPurchased", value: newValue } })
    setProductsPurchased(newValue)
  }

  function handleAddProduct(item) {
    if (item && item.value !== 0) {
      const exist = productsPurchased.filter(x => x.label === item.label)
      if (exist.length > 0)
        handleError({ target: { name: "productsPurchased" } }, "Produto já adicionado")
      else if (product && product.value !== "" && product.value !== null) {
        const newValue = [...productsPurchased, { value: item.value, label: item.label, titulo: item.titulo, quantidade: 1 }]
        setProductsPurchased(newValue)
        handleChange({ target: { name: "productsPurchased", value: newValue } })
        setProduct(null)
      }
    }
  }

  function handleExcludeProduct() {
    if (productToExclude) {
      const newValue = productsPurchased.slice().filter(x => x.label !== productToExclude.label)
      setProductsPurchased(newValue)
      handleChange({ target: { name: "productsPurchased", value: newValue } })
      setProductToExclude({})
      toggle()
    }
  }

  async function getResellers() {
    try {
      const client = new ApolloClient({
        uri: `${HOST}/graphql`,
        cache: new InMemoryCache(),
      });
      let { data } = await client.query({
        query: gql`
          query getResellers {
            locaisRevendas {
              id
              nome
              email
              bairro
              cidade
              estado
            }
          }
        `,
      });
      setResellers(data.locaisRevendas.map(item => ({
        label: item.nome + (item.bairro != null ? " (" + item.bairro + ")" : ""),
        value: item.id,
        email: item.email
      })))
    } catch { }
  }

  if (successPage) {
    return (
      <SuccessScreen />
    )
  }

  return (
    <>
      {
        pageInfo ?
          <div className="pg-pw-div-content">
            <div className="pg-rg-div-description">
              <div className="pg-pw-lbl-title">
                <span>Garantia</span>
              </div>
              <p>{simpleText2br(pageInfo?.descricao)}</p>
            </div>

            <form autoComplete="no" onSubmit={handleSubmit}>
              <span>Data da compra*</span>
              <InputMask
                alwaysShowMask={false}
                mask={dateMask}
                maskChar={null}
                value={values.purchaseDate}
                name="purchaseDate"
                placeholder={placeholder}
                className="general-input"
                onChange={handleChange}
                onFocus={(e) => handleError(e)}
                style={{
                  border: errors.purchaseDate ? "1px solid #FF0000" : "",
                }}
              />
              {errors.purchaseDate && (
                <span className="general-lbl-info-message">{errors.purchaseDate}</span>
              )}
              <span>Nome completo*</span>
              <input
                type="text"
                name="name"
                placeholder="Ex: José Manuel"
                value={values.name}
                onChange={handleChange}
                onFocus={(e) => {
                  e.target.placeholder = ""
                  handleError(e)
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Ex: José Manuel"
                }}
                className="pg-rg-input"
                style={{
                  border: errors.name ? "1px solid #FF0000" : "",
                }}
              />
              {errors.name && <span className="general-lbl-info-message">{errors.name}</span>}
              <span>E-mail do cliente*</span>
              <input
                name="customerEmail"
                placeholder="nome@email.com.br"
                value={values.customerEmail}
                onChange={handleChange}
                onFocus={(e) => {
                  e.target.placeholder = ""
                  handleError(e)
                }}
                onBlur={(e) => {
                  e.target.placeholder = "nome@email.com.br"
                }}
                className="pg-rg-input"
                style={{
                  border: errors.customerEmail ? "1px solid #FF0000" : "",
                }}
              />
              {errors.customerEmail && (
                <span className="general-lbl-info-message">{errors.customerEmail}</span>
              )}
              <span>Telefone do cliente*</span>
              <InputMask
                alwaysShowMask={false}
                maskChar=""
                mask={phoneMask}
                value={values.phone}
                name="phone"
                placeholder="(11) 99999-9999"
                className="general-input"
                onChange={handleChange}
                onFocus={(e) => handleError(e)}
                style={{
                  border: errors.phone ? "1px solid #FF0000" : "",
                }}
              />
              {errors.phone && (
                <span className="general-lbl-info-message">{errors.phone}</span>
              )}
              <span>Data de nascimento*</span>
              <InputMask
                alwaysShowMask={false}
                mask={dateMask}
                maskChar=""
                value={values.birthDate}
                name="birthDate"
                placeholder={placeholder}
                className="general-input"
                onChange={handleChange}
                onFocus={(e) => handleError(e)}
                style={{
                  border: errors.birthDate ? "1px solid #FF0000" : "",
                }}
              />
              {errors.birthDate && (
                <span className="general-lbl-info-message">{errors.birthDate}</span>
              )}
              <span>Tipo de cliente*</span>
              <div className="container-radio-button">
                <div className="content-radio-button">
                  <input
                    type="radio"
                    name="customerType"
                    value="Pessoa Física"
                    className={(errors.customerType ? "input-radio-invalid" : "")}
                    checked={values.customerType === "Pessoa Física"}
                    onChange={(e) => {
                      handleChange(e);
                      handleError({ target: { name: "customerType" } })
                    }}
                  />
                  <span>Pessoa Física</span>
                </div>
                <div className="content-radio-button">
                  <input
                    type="radio"
                    name="customerType"
                    value="Pessoa Jurídica"
                    className={(errors.customerType ? "input-radio-invalid" : "")}
                    checked={values.customerType === "Pessoa Jurídica"}
                    onChange={(e) => {
                      handleChange(e);
                      handleError({ target: { name: "customerType" } })
                    }}
                  />
                  <span>Pessoa Jurídica</span>
                </div>
              </div>
              {errors.customerType && (
                <span className="general-lbl-info-message" >{errors.customerType}</span>
              )}
              <span>Nome da revenda*</span>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: 25,
                    border: errors.resellerValue ? "1px solid #FF0000" : "1px solid #707070",
                    boxShadow: "none",
                    background: "black",
                    padding: 8,
                    cursor: "pointer",
                    ":hover": {
                      borderColor: "#707070",
                    },
                  }),
                  ...baseSelectStyles
                }}
                placeholder="Selecione a revenda"
                options={resellers}
                name="resellerValue"
                onChange={(e, index) => {
                  handleSelectResellerChange(e);
                }}
                isClearable={true}
                onFocus={(e) => handleError({ target: { ...e.target, name: "resellerValue" } })}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 20,
                  colors: {
                    ...theme.colors,
                    primary75: "transparent",
                    primary50: "transparent",
                    primary25: "transparent",
                    primary: "black",
                  },
                })}
              />
              {errors.resellerValue && (
                <span className="general-lbl-info-message">{errors.resellerValue}</span>
              )}
              <span>Chave de acesso da Nota Fiscal*</span>
              <InputMask
                alwaysShowMask={false}
                mask={"99–9999–99999999999999–99–999–999999999–999999999–9"}
                maskChar={null}
                value={values.notaFiscalKeyAccess}
                name="notaFiscalKeyAccess"
                placeholder={"99–9999–99999999999999–99–999–999999999–999999999–9"}
                className="pg-rg-input"
                onChange={handleChange}
                onFocus={(e) => {
                  e.target.placeholder = ""
                  handleError(e)
                }}
                onBlur={(e) => {
                  e.target.placeholder = "99–9999–99999999999999–99–999–999999999–999999999–9"
                }}
                style={{
                  border: errors.notaFiscalKeyAccess ? "1px solid #FF0000" : "",
                }}
              />
              {errors.notaFiscalKeyAccess && (
                <span className="general-lbl-info-message">{errors.notaFiscalKeyAccess}</span>
              )}
              <span>Número da Nota Fiscal*</span>
              <input
                type="text"
                name="notaFiscalNumber"
                placeholder="Ex: 1234567890"
                value={values.notaFiscalNumber}
                onChange={handleChange}
                onFocus={(e) => {
                  e.target.placeholder = ""
                  handleError(e)
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Ex: 1234567890"
                }}
                className="pg-rg-input"
                style={{
                  border: errors.notaFiscalNumber ? "1px solid #FF0000" : "",
                }}
              />
              {errors.notaFiscalNumber && (
                <span className="general-lbl-info-message">{errors.notaFiscalNumber}</span>
              )}
              <span>Produtos adquiridos*</span>
              <Select
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "100%",
                  }),
                  control: (base) => (
                    {
                      ...base,
                      borderRadius: 25,
                      border: errors.productsPurchased ? "1px solid #FF0000" : "1px solid #707070",
                      boxShadow: "none",
                      background: "black",
                      padding: 8,
                      cursor: "pointer",
                      ":hover": {
                        borderColor: "#707070",
                      },
                    }),
                  ...baseSelectStyles
                }}
                isMulti={false}
                placeholder="Selecione o código do produto"
                options={codeProducts}
                value={product}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                name={"productsPurchased"}
                onChange={(e) => {
                  setProduct(e)
                }}
                isClearable={false}
                onFocus={(e) => handleError({ target: { ...e.target, name: "productsPurchased" } })}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 20,
                  colors: {
                    ...theme.colors,
                    primary75: "transparent",
                    primary50: "transparent",
                    primary25: "transparent",
                    primary: "black",
                  },
                })}
              />
              {errors.productsPurchased && (
                <span className="general-lbl-info-message">{errors.productsPurchased}</span>
              )}
              <button type="button" className="pg-pw-btn-add-product" onClick={() => handleAddProduct(product)}>ADICIONAR PRODUTO</button>

              {
                productsPurchased.length > 0 &&
                <table className="pg-pw-table-products">
                  <tbody>
                    <tr>
                      <th>Produto</th>

                      <th className="pg-pw-table-center-text" width="130px" >Qtd.</th>
                    </tr>
                    {productsPurchased.length > 0 && productsPurchased.map((item, index) => (
                      <tr key={item.value}>
                        <td>{item.label}</td>
                        <td className="pg-pw-table-center-text">
                          <FiMinus onClick={() => handleChangeProductQuantity(index, -1)} />
                          <input
                            onChange={(e) => { handleChangeProductQuantity(index, e.target.value, 2) }}
                            name={`${index}-quantity`}
                            value={item.quantidade}
                            className="pg-rg-input pg-rg-input-product-quantity"
                          // style={{
                          //   border: errors.productsPurchased[index] ? "1px solid #FF0000" : "",
                          // }}
                          />
                          <FiPlus onClick={() => handleChangeProductQuantity(index, 1)} />
                          <FiTrash2 onClick={() => {
                            setProductToExclude(item)
                            toggle()
                          }} />
                          <ConfirmDialog open={open} toggle={toggle}
                            title={"Exclusão de produto"} body={"Tem certeza que deseja excluir o produto?"}
                            textFirstOption={"Sim"} functionFirstOption={handleExcludeProduct}
                            textSecondOption={"Não"} functionSecondOption={toggle} />
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              }

              {
                loading ? (
                  <button type="submit" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button type="submit">
                    {hasSending ? `ENVIADO` : `ENVIAR`}
                  </button>
                )}
            </form>
          </div>
          :
          <div className="pg-rg-div-content-skeleton">
            <SkeletonTheme color="#202020" highlightColor="#444">
              <Skeleton style={{ height: '80px', marginBottom: '10px', marginTop: '20px' }} />
              <Skeleton style={{ height: '150px', marginTop: '15px' }} />
              <Skeleton style={{ height: '250px', marginTop: '15px' }} />
              <Skeleton style={{ height: '350px', marginTop: '15px' }} />
            </SkeletonTheme>
          </div>
      }
    </>
  );
};

export default ProductWarranty;