import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import './style.scss'

const Product3DModal = (props) => {

    const url = props.url

    return (
        props && <>
            <Modal centered isOpen={props.open} toggle={props.toggle} contentClassName="comp-confirmation-content">
                <ModalHeader className="comp-confirmation-header" toggle={props.toggle}>{props.title}</ModalHeader>
                <ModalBody className="comp-confirmation-body">
                    <div className="prdt-div-3D-viewer">
                        <iframe pagePreset="true" title="3d-viewer"
                            src={`https://elettromec.blob.core.windows.net/uploads/assets/Model3_D_13ad7d9f5c.html?url=${url}`} frameborder="0" allowfullscreen="true" mozallowfullscreen="true"
                            webkitallowfullscreen="true" scrolling="no" />
                    </div>
                </ModalBody>
                <ModalFooter className="comp-confirmation-footer"></ModalFooter>
            </Modal>
        </>
    );
}

export default Product3DModal