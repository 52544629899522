import axios from "axios";
import { HOST } from '../api'

const sendRequestForgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${HOST}/auth/forgot-password`,
      {
        email: email,
      },
    );
    return response.status;
  } catch (error) {
    return error.response;
  }
};

const resetForgotPassword = async (
  code,
  password,
  passwordConfirmation,
) => {
  try {
    const response = await axios.post(
      `${HOST}/auth/reset-password`,
      { code, password, passwordConfirmation },
    );
    return response.status;
  } catch (error) {
    return error.response;
  }
};

export { sendRequestForgotPassword, resetForgotPassword };
