import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { login } from '../../services/login'
import Context from "../../context/context";
import { Spinner } from "reactstrap";

import { FiEyeOff, FiEye } from "react-icons/fi";

import "./style.scss";
import { Profiles } from "../../utils/profiles";

const Login = () => {
  let history = useHistory();
  const { menu, setMenu, menuBkp, Elettromec, setIsSigned } = useContext(Context);

  const [userProfile, setUserProfile] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [emailValidate, setEmailValidate] = useState(true);
  const [emailValidateMessage, setEmailValidateMessage] = useState(
    "Por favor, informe o e-mail",
  );
  const [passwordValidate, setPasswordValidate] = useState(true);

  const [formValidate, setFormValidate] = useState(true);
  const [formValidateMessage, setFormValidateMessage] = useState(
    "E-mail ou senha inválidos",
  );

  useEffect(() => {
    setEmailValidate(true);
    setPasswordValidate(true);
    setFormValidate(true);
    window.scrollTo(0, 0);
  }, []);

  function signInConsumidor() {
    setIsSigned(true);
    window.localStorage.setItem(`${Elettromec}:isSigned`, 'true');
    window.localStorage.setItem(`${Elettromec}:userProfile`, 4);

    handleMenu(menuBkp)

    history.push("/home");
  }

  function handleMenu(menuData) {
    let tempMenu
    if (parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === Profiles.AssistenciaTecnica ||
      parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === Profiles.Consumidor) {

      tempMenu = menuData.filter((item) => item.rota !== "projetos");

      if (parseInt(window.localStorage.getItem(`${Elettromec}:userProfile`)) === Profiles.Consumidor) {
        tempMenu = tempMenu.filter((item) => item.rota !== "perfil");
      }

      setMenu(tempMenu)
    }
    else {
      setMenu(menuData)
    }
  }

  const signIn = useCallback(
    async (event) => {
      event.preventDefault();
      setFormValidate(true);
      setFormValidateMessage(
        "E-mail ou senha incorretos",
      );

      if (!email || email === "") {
        setEmailValidateMessage("Por favor, informe o e-mail");
        setEmailValidate(email && email !== "");
        return;
      } else {
        setEmailValidateMessage("Por favor, informe um e-mail válido");
        var regexEmail = /\S+@\S+\.\S+/;

        if (!regexEmail.test(email)) {
          setEmailValidate(false);
          return;
        }
      }

      if (!password || password === "") {
        setPasswordValidate(password && password !== "");
        return;
      }

      try {
        setLoading(true)
        const data = await login(email, password)

        const profile = data.user.perfil.id;

        setUserProfile(profile);

        let menuData = [];
        menuData = menuBkp.filter((item) => item.rota !== "login" && item.rota !== 'logout-consumidor');

        setIsSigned(true);
        window.localStorage.setItem(`${Elettromec}:isSigned`, 'true');
        window.localStorage.setItem(`${Elettromec}:token`, data.jwt);
        window.localStorage.setItem(`${Elettromec}:userName`, data.user.username);
        window.localStorage.setItem(`${Elettromec}:userProfile`, data.user.perfil.id);

        handleMenu(menuData)

        history.push("/home");
        setLoading(false)
      } catch (error) {
        setLoading(false)
        //if (error && error.response && error.data.data && error.response.data.data[0].messages[0].id && error.response.data.data[0].messages[0].id === 'Auth.form.error.blocked')
        //  setFormValidateMessage("Usuário bloqueado. Por favor, entre em contato com nosso suporte.")
        setFormValidate(false);
        //console.log(error.response)
      }
    },
    [email, password, Elettromec, history, menuBkp, setIsSigned, setMenu, userProfile],
  );

  return (
    <>
      <div className="pg-lg-div-content">
        <span className="pg-lg-lbl-title">Bem-vindo</span>
        <div className="pg-lg-div-header">
          <h3>Faça seu login</h3>
        </div>
        <form onSubmit={signIn}>
          <input
            id="email"
            onFocus={(e) => {
              setEmailValidate(true);
              setFormValidate(true);
              e.target.placeholder = ""
            }}
            className="pg-lg-input"
            onBlur={(e) => {
              setEmail(e.target.value)
              e.target.placeholder = "nome@email.com.br"
            }}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailValidate(true);
              setFormValidate(true);
            }}
            placeholder="nome@email.com.br"
            style={{
              border: !emailValidate ? "1px solid #FF0000" : "",
            }}
          />
          {emailValidate ? (
            <div style={{ height: "10px" }}></div>
          ) : (
            <div style={{ textAlign: "center", height: "35px" }}>
              <span className="pg-login-label-info">{emailValidateMessage}</span>
              <br />
            </div>
          )}

          <div className="pg-lg-div-password">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="pg-lg-input"
              onFocus={(e) => {
                setFormValidate(true);
                setPasswordValidate(true);
                e.target.placeholder = ""
              }}
              onBlur={(e) => {
                setPassword(e.target.value)
                e.target.placeholder = "●●●●●●"
              }}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordValidate(true);
                setFormValidate(true);
              }}
              placeholder="●●●●●●"
              style={{
                border: !passwordValidate ? "1px solid #FF0000" : "",
              }}
            />
            <i onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FiEye size="23" /> : <FiEyeOff size="23" />}
            </i>
          </div>
          {passwordValidate && formValidate ? (
            <div style={{ height: "10px" }}></div>
          ) : !passwordValidate ? (
            <div style={{ textAlign: "center", height: "35px" }}>
              <span className="pg-login-label-info">Por favor, informe a senha</span>
              <br />
            </div>
          ) : (
            <div style={{ textAlign: "center", height: "35px" }}>
              <span className="pg-login-label-info">{formValidateMessage}</span>
            </div>
          )}

          {
            loading ? <Spinner className="pg-lg-svg-spinner" color="light" size={'20'} /> :
              <button
                type="submit"
                className="pg-lg-btn pg-lg-btn-acessar"
                id="btnLogin"
                name="btnLogin"
                block
              >
                ENTRAR
              </button>
          }
          <div
            style={{
              textAlign: "center",
              height: "25px",
              maxHeight: "25px",
              marginTop: "5px",
              display: "flex",
            }}
          >
            <hr className="pg-lg-hr" />
            <span className="pg-login-label-info">OU </span>
            <hr className="pg-lg-hr" />
          </div>
          <button
            type="button"
            className="pg-lg-btn-consumidor"
            id="btnSigninConsumidor"
            name="btnSigninConsumidor"
            onClick={signInConsumidor}
            block
          >
            ACESSAR COMO CLIENTE
          </button>
        </form>

        <div className="pg-lg-div-signup">
          <Link to="/cadastro">Ainda não tem um cadastro?</Link>
          <Link to="/email-recovery">Esqueceu a senha?</Link>
        </div>
      </div>
    </>
  );
};

export default Login;
