import React, { useContext, useEffect, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi'
import { useHistory, useRouteMatch } from 'react-router-dom';

import Context from '../../context/context';
import { getProjectInfo, updateProject } from '../../services/projects';
import ConfirmDialog from '../../components/shared/confirmation';

import PDFPage from '../share-pdf'
import './style.scss'
import '../../style.scss'

const ViewProject = () => {

  const { params } = useRouteMatch();
  const id = params.id;

  const [project, setProject] = useState()
  const [selectedProduct, setSelectedProduct] = useState()

  const { Elettromec, setSelectedProject, setShowMenu, setShowHeader, setShowFooter } = useContext(Context)
  const history = useHistory()

  async function findProject() {
    try {
      const data = await getProjectInfo(window.localStorage[`${Elettromec}:token`], id)
      if (!data || data.projetos.length === 0) {
        history.push('/projetos')
        return
      }

      let dataFinal
      if (data && data.projetos.length > 0) {
        dataFinal = data.projetos.map((project) => Object.assign({}, project, { qtd_produtos: project.produtos.length }))
      }

      setProject(dataFinal[0])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    findProject()
    setShowHeader(true)
    setShowFooter(true)
    setShowMenu(true)
    window.scrollTo(0, 0)
  }, []);

  // function shareProject() {
  //     if (navigator.share && project) {
  //         navigator.share({
  //             title: `${project.nome_projeto}\nCliente: ${project.nome_cliente}`,
  //             text: `${project.nome_projeto}\nCliente: ${project.nome_cliente}\n\n` +
  //                 (project.produtos && project.produtos.length > 0 ? project.produtos.map(product =>
  //                     `${product.titulo}\n${product.referencia}\n${HOST}/produtos/${product.id}\n \n`
  //                 ) : '')
  //             //url: location,
  //         })
  //             .then(() => console.log('Successful share'))
  //             .catch((error) => console.log('Error sharing', error));
  //     }
  // }

  async function deleteProduct(productId) {
    try {
      await updateProject(project.id, productId, project.produtos, null, window.localStorage[`${Elettromec}:token`])
      await findProject()
      setSelectedProduct(null)
    }
    catch (error) {
      console.log('error ao atualizar projeto', error)
    }
  }


  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  async function handleExcludeProduct() {
    if (selectedProduct) {
      deleteProduct(selectedProduct)
      toggle()
    }
  }

  function handleAddMoreProducts() {
    setSelectedProject({
      value: id,
      label: project.nome_projeto
    })
    history.push('/produtos')
  }

  return (
    <div className="project-div-content">

      <div className="project-div-header">
        <button type="button" onClick={() => { history.push('/projetos') }}>
          <BsChevronLeft />
          <span>VOLTAR</span>
        </button>

        <span className="lbl-produtos">Projeto</span>
      </div>
      {
        project &&
        <>
          <div className="vw-project-div-project">
            <div className="vw-project-div-list-info">
              <span className="vw-project-lbl-project-title">
                {project.nome_projeto}
              </span>
              <span className="vw-project-lbl-client">
                {project.nome_cliente}
              </span>
              <span className="vw-project-lbl-products">
                {project.qtd_produtos > 0 ? (
                  project.qtd_produtos > 1 ? `${project.qtd_produtos} produtos` : `${project.qtd_produtos} produto`
                ) : "Nenhum produto"}
              </span>
            </div>
            <PDFPage />

          </div>
          {
            project && project.produtos.map(product => {
              return (
                <div key={product.id}>
                  <hr className="vw-project-hr" />
                  <div className="vw-project-div-list">
                    <div className="vw-project-list-img">
                      {
                        product.galeria && product.galeria.length > 0 &&
                        <img src={product.galeria[0].url} alt={`product-${product.id}`} />
                      }
                    </div>
                    <div className="vw-project-list-product-name">
                      <span className="vw-project-lbl-ref">{product.referencia}</span>
                      <span className="vw-project-lbl-title">{product.titulo}</span>
                    </div>
                    <div className="vw-project-sgv-delete"><FiTrash2 size={20} onClick={() => {
                      setSelectedProduct(product.id)
                      toggle();
                    }} /></div>
                    <ConfirmDialog open={open} toggle={toggle}
                      title={"Exclusão de produto"} body={"Tem certeza que deseja excluir o produto?"}
                      textFirstOption={"Sim"} functionFirstOption={handleExcludeProduct}
                      textSecondOption={"Não"} functionSecondOption={toggle} />
                  </div>
                </div>
              )
            })
          }
        </>
      }
      <hr className="vw-project-hr" />
      <div className="vw-project-a-add-product">
        <button type="button" onClick={handleAddMoreProducts}>+ ADICIONAR PRODUTOS</button>
      </div>
      <hr className="vw-project-hr" />
    </div>
  )
}


export default ViewProject