import { useState, useEffect } from "react";
import { resetForgotPassword } from "../../services/forgot-password";

const useForm = (validate) => {
  const [values, setValues] = useState({
    password: "",
    passwordConfirm: "",
  });

  const [errors, setErrors] = useState({});
  const [hasSending, setHasSending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasCode, setHasCode] = useState("");

  async function sendRequest() {
    setLoading(true);
    const request = await resetForgotPassword(
      hasCode,
      values.password,
      values.passwordConfirm,
    );

    if (request === 200) {
      setHasSending(true);
      setLoading(false);
    } else {
      setHasSending(true);
      setLoading(false);
      setErrors({
        request: "Erro ao trocar a senha, tente novamente mais tarde",
      });
    }
  }

  const handleError = (e) => {
    const { name } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e, code) => {
    e.preventDefault();

    setErrors(validate(values));
    setHasCode(code);
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      sendRequest();
    }
  }, [errors]);

  return {
    handleChange,
    values,
    errors,
    handleSubmit,
    hasSending,
    loading,
    handleError
  };
};

export default useForm;
