import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import './style.scss';

function LoadingProductDetail() {
    return (
        <>
            {Array(1)
                .fill('')
                .map((_, i) => (
                    <div key={i} className="prdt-skeleton">
                        <SkeletonTheme color="#202020" highlightColor="#444">
                            <Skeleton style={{ height: '300px' }} />

                            <Skeleton style={{ height: '22px', marginTop: '45px' }} />
                            <Skeleton style={{ height: '22px' }} />
                            <br />


                            <Skeleton style={{ height: '300px', marginTop: '50px' }} />

                        </SkeletonTheme>
                    </div>
                ))
            }
        </>
    )
}

export default LoadingProductDetail;