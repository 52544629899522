import React, { } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function LoadingFilter() {
    return (
        <>
            {
                Array(4)
                    .fill('')
                    .map((_, i) => (
                        <SkeletonTheme key={i} color="#202020" highlightColor="#444" key={i} >
                            <Skeleton style={{ height: '17px' }} />
                            {Array(2)
                                .fill('')
                                .map((_, k) => (
                                    <div key={k}>
                                        <Skeleton style={{ borderRadius: '50px', height: '20px', width: '20px', marginBottom: '5px', marginRight: '5px' }} />
                                        <Skeleton style={{ height: '20px', width: '100px', marginBottom: '5px', marginRight: '10px' }} />
                                    </div>
                                ))}
                        </SkeletonTheme>
                    ))
            }
        </>
    )
}

export { LoadingFilter }