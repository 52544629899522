import axios from "axios";
import { HOST } from "../api";
import { convertDateFormat } from '../../utils/index'

async function sendRequestProductWarranty(values) {
  try {
    const purchaseDate = convertDateFormat(values.purchaseDate)
    const birthDate = convertDateFormat(values.birthDate)

    const response = await axios.post(`${HOST}/garantias`, {
      data_compra: purchaseDate,
      nome_completo: values.name,
      data_nascimento: birthDate,
      email_cliente: values.customerEmail,
      telefone: values.phone,
      tipo_cliente: values.customerType,
      local_revenda: values.resellerValue,
      revenda: values.resellerData,
      chave_nota_fiscal: values.notaFiscalKeyAccess,
      numero_nota_fiscal: values.notaFiscalNumber,
      produtos: values.productsPurchased,
      produtos_detalhes: values.productsInformation
    });
    return response.status;
  } catch (error) {
    return error.response;
  }
}

export { sendRequestProductWarranty };
