import React, { useContext, useEffect, useState } from "react";
import Context from "../../context/context";
import Select from "react-select";
import { FiX } from "react-icons/fi";
import { Spinner } from 'reactstrap'
import { createProject, getProjectInfo, updateProject } from "../../services/projects";


import "./style.scss";
import validateInfo from "./validateInfo";

const ProjectList = (props) => {

    const [newProject, setNewProject] = useState(false)
    const [validator, setValidator] = useState(true)
    const [projectName, setProjectName] = useState()
    const [clientName, setClientName] = useState()
    const [projectNameValidator, setProjectNameValidator] = useState("")
    const [messageValidator, setMessageValidator] = useState("")
    const [loading, setLoading] = useState(false)

    const [classContentName, setClassContentName] = useState("component-project-search-FadeInUp")


    const [projects, setProjects] = useState([])

    const {
        Elettromec,
        setShowMenuProject,
        selectedProject,
        setSelectedProject
    } = useContext(Context);

    async function loadApi() {
        const data = await getProjectInfo(window.localStorage[`${Elettromec}:token`])

        if (data.projetos) {
            let projectList = data.projetos.map(x => {
                return {
                    value: x.id,
                    label: x.nome_projeto
                }
            })

            setProjects(projectList)
        }
    }

    useEffect(() => {
        loadApi()
    }, []);

    const selectStyles = {
        container: (base) => ({
            ...base,
            width: "100%",
            textAlign: "left",
        }),
        input: (base) => ({
            ...base,
            color: "#ffffff",
        }),
        control: (base) => ({
            ...base,
            borderRadius: 25,
            border: validator ? "1px solid #707070" : "1px solid #FF0000",
            boxShadow: "none",
            background: "black",
            padding: 8,
            cursor: "pointer",
            ":hover": {
                borderColor: "#707070",
            },
        }),
        valueContainer: (base) => ({
            ...base,
            font: "normal normal 300 16px/22px Josefin Sans",
            background: "transparent",
            color: "white",
            height: 35,
        }),
        singleValue: (base) => ({
            ...base,
            font: "normal normal 300 16px/22px Josefin Sans",
            background: "transparent",
            color: "white",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            background: "transparent",
            color: "white",
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 25,
            border: "1px solid #707070",
            background: "black",
            padding: 8,
        }),
        option: (base) => ({
            ...base,
            font: "normal normal 300 16px/22px Josefin Sans",
            background: "transparent",
            height: "100%",
            cursor: "pointer",
        }),
    };

    function handleChangeSelect(value) {
        setMessageValidator("")
        setValidator(true)
        setSelectedProject(value)
    }

    function handleCancelProject() {
        if (newProject) {
            setNewProject(false)
            setProjectName("")
            setClientName("")
            setProjectNameValidator("")
        }
        else {
            setClassContentName(" component-project-search-FadeInOut")
            setTimeout(() => {
                setShowMenuProject(false)
                clearTimeout()
            }, 500);
        }
    }

    async function handleSaveProject() {
        setMessageValidator("")
        setProjectNameValidator("")
        if (newProject) {
            handleSaveNewProject()
        }
        else {
            setLoading(true)
            setMessageValidator("")
            setValidator(true)

            if (!selectedProject.value) {
                setMessageValidator("Selecione um projeto para continuar")
                setValidator(false)
                setLoading(false)
                return
            }

            try {
                let data = await getProjectInfo(window.localStorage[`${Elettromec}:token`], selectedProject.value)

                if (data.projetos[0].produtos) {
                    const exist = data.projetos[0].produtos.find(x => parseInt(x.id) === parseInt(props.productId))

                    if (exist) {
                        setValidator(false)
                        setMessageValidator("O produto selecionado já existe no projeto")
                        return
                    }
                }

                let productList

                if (data.projetos[0].produtos && data.projetos[0].produtos.length > 0) {
                    productList = data.projetos[0].produtos.map(x => parseInt(x.id))
                    productList = [...productList, parseInt(props.productId)]
                }
                else
                    productList = [parseInt(props.productId)]

                await updateProject(selectedProject.value, null, productList, null, window.localStorage[`${Elettromec}:token`])
                setShowMenuProject(false)
            } catch (error) {

            }
            finally {
                setLoading(false)
            }

        }
    }

    async function handleSaveNewProject() {
        try {
            setLoading(true)
            const errors = validateInfo({ projectName, clientName })

            setProjectNameValidator(errors.projectName)
            if (errors.error) {
                setLoading(false)
                return;
            }

            const data = await createProject(projectName, clientName, null, window.localStorage[`${Elettromec}:token`])
            if (!data.id) {
                setLoading(false)
                setProjectNameValidator(data)
                return
            }

            await loadApi()
            setSelectedProject({ value: data.id, label: data.nome_projeto })
            setNewProject(false)
            setProjectName("")
            setClientName("")
            setMessageValidator("")
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            setMessageValidator("Erro ao salvar projeto. Por favor, tente novamente")
        }
    }

    return (
        <div className={"component-project-div-content " + classContentName}>

            <div className="component-project-div-header">
                <span>{props.productName}</span>
                <div><FiX onClick={() => {
                    setClassContentName(" component-project-search-FadeInOut")
                    setTimeout(() => {
                        setShowMenuProject(false)
                        clearTimeout()
                    }, 500);

                }} size={25} /></div>
            </div>

            <div className="component-project-div-form">
                {!newProject && projects && projects.length > 0 &&
                    <>
                        <Select
                            styles={selectStyles}
                            placeholder="Selecione"
                            options={projects}
                            isSearchable
                            name="occupation"
                            value={selectedProject}
                            onChange={e => handleChangeSelect(e)}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 20,
                                colors: {
                                    ...theme.colors,
                                    primary75: "transparent",
                                    primary50: "transparent",
                                    primary25: "transparent",
                                    primary: "transparent",
                                },
                            })}
                        />
                        {messageValidator !== "" &&
                            <div className="component-project-div-validator"><label className="component-project-lbl-validator">{messageValidator}</label></div>
                        }
                    </>
                }

                {
                    newProject &&
                    <div className="component-project-div-form">
                        <span>Nome do projeto</span>
                        <input
                            type="text"
                            id="projectName"
                            value={projectName}
                            className="general-input"
                            onBlur={(e) => setProjectName(e.target.value)}
                            onChange={(e) => {
                                setProjectName(e.target.value);
                            }}
                            onFocus={() => {
                                setProjectNameValidator("")
                                setMessageValidator("")
                            }}
                            style={{
                                border: projectNameValidator !== "" ? "1px solid #FF0000" : "",
                            }}
                        />
                        {projectNameValidator !== "" &&
                            <div className="component-project-div-validator"><label className="component-project-lbl-validator">{projectNameValidator}</label></div>
                        }

                        <span>Nome do cliente </span><span style={{ font: 'normal normal 300 14px/14px Josefin Sans' }}>(opcional)</span>
                        <input
                            type="text"
                            id="clientName"
                            value={clientName}
                            className="general-input"
                            onBlur={(e) => setClientName(e.target.value)}
                            onChange={(e) => {
                                setClientName(e.target.value);
                            }}
                            onFocus={() => {
                                setMessageValidator("")
                            }}
                        />
                    </div>
                }
                {(newProject || (!newProject && projects && projects.length > 0)) &&
                    <div className="project-a-form">
                        {loading ? <Spinner color="light" /> : <button className="project-lnk-save" onClick={handleSaveProject}>SALVAR</button>}
                        <button onClick={handleCancelProject} className="project-lnk-cancel">CANCELAR</button>
                    </div>}

                {!newProject && <div style={{ textAlign: "center" }}><button className="component-project-add" onClick={() => {
                    setNewProject(true)
                    setValidator(true)
                    setMessageValidator("")
                }}>+ CRIAR NOVO PROJETO</button></div>}

            </div>


        </div >
    );
};

export default ProjectList;
