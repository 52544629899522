import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Context from "../../context/context";
import useForm from "./useForm";
import validate from "./validateInfo";

import { FiEyeOff, FiEye } from "react-icons/fi";

import "./style.scss";

const ForgotPasswordPage = () => {
  const { setMenu, setIsSigned, Elettromec } = useContext(Context);
  const { handleChange, values, errors, handleSubmit, hasSending, loading, handleError } =
    useForm(validate);

  const params = new URLSearchParams(window.location.search);

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordConfirmationVisibility = () => {
    setPasswordConfirmationShown(passwordConfirmationShown ? false : true);
  };

  const Logout = () => {
    window.localStorage.removeItem(`${Elettromec}:isSigned`);
    window.localStorage.removeItem(`${Elettromec}:token`);
    window.localStorage.removeItem(`${Elettromec}:userName`);
    window.localStorage.removeItem(`${Elettromec}:userProfile`);
    setIsSigned(false);

    setMenu([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Logout();
  }, []);

  return (
    <>
      <div className="pg-fg-pw-div-content">
        <div className="pg-fg-pw-div-header">
          <h3>REDEFINIÇÃO DE SENHA</h3>
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(e, params.get("code"));
          }}
        >
          {hasSending ? (
            <>
              <div style={{ height: "86px" }}></div>
              <span className="label-response">
                {errors.request ? errors.request : `Senha alterada com sucesso`}
              </span>
              <Link to="/">
                <button type="button" block>
                  INÍCIO
                </button>
              </Link>
              <div style={{ height: "86px" }}></div>
            </>
          ) : (
            <>
              <span>Senha</span>
              <div className="pg-fg-pw-div-inputs">
                <input
                  id="password"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onFocus={(e) => {
                    e.target.placeholder = ""
                    handleError(e)
                  }}
                  onBlur={(e) => e.target.placeholder = "●●●●●●"}
                  className="pg-fg-pw-input"
                  placeholder="●●●●●●"
                  style={{
                    border: errors.password ? "1px solid #FF0000" : "",
                  }}
                />
                <i onClick={togglePasswordVisibility}>
                  {passwordShown ? <FiEye size="23" /> : <FiEyeOff size="23" />}
                </i>
              </div>
              {errors.password && (
                <span className="pg-fg-pw-label-info">{errors.password}</span>
              )}
              <br></br>
              <span>Confirmação de senha</span>
              <div className="pg-fg-pw-div-inputs">
                <input
                  id="passwordConfirm"
                  type={passwordConfirmationShown ? "text" : "password"}
                  name="passwordConfirm"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  onFocus={(e) => {
                    e.target.placeholder = ""
                    handleError(e)
                  }}
                  onBlur={(e) => e.target.placeholder = "●●●●●●"}
                  className="pg-fg-pw-input"
                  placeholder="●●●●●●"
                  style={{
                    border: errors.passwordConfirm ? "1px solid #FF0000" : "",
                  }}
                />
                <i onClick={togglePasswordConfirmationVisibility}>
                  {passwordConfirmationShown ? (
                    <FiEye size="23" />
                  ) : (
                    <FiEyeOff size="23" />
                  )}
                </i>
              </div>
              {errors.passwordConfirm && (
                <span className="pg-fg-pw-label-info">{errors.passwordConfirm}</span>
              )}

              {loading ? (
                <button type="submit" disabled block>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button type="submit" block>
                  ENVIAR
                </button>
              )}
            </>
          )}
        </form>

        <div className="pg-lg-div-signup">
          <Link to="/cadastro">Ainda não tem um cadastro?</Link>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
