import React, { useState, useEffect, useCallback } from 'react'
import { Fade } from 'reactstrap';
import ProdutosCarousel from '../products-carousel';
import api from '../../services/api'
import { useLocation, Link } from 'react-router-dom'


import LoadingProducts from './loadingProducts'

import './style.scss';
//import { ContentDiv, CategoryItem, CategoryGradient, CategoryButton, ButtonLabel, Content, Header, SeeMoreProductsButton, ButtonLabelSeeMore } from './style'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'


const CategoriasProdutos = ({ ...props }) => {

  const location = useLocation().pathname.replace('/', '')

  const [categories, setCategories] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [showAllProducts, setShowAllProducts] = useState(false)

  const handleCategoryOpen = useCallback((categoria) => {
    const index = categories.indexOf(categoria)
    setCategories(
      state => state.map((categoria, i) => {
        if (i === index) {
          categoria.isOpen = !categoria.isOpen
        }
        else
          categoria.isOpen = false
        return categoria
      }))
  }, [categories])

  useEffect(() => {
    async function loadApi() {
      try {
        let { data } = await api.get(`/categorias`)

        let tempCategories = data.map(cat => ({
          isOpen: false,
          ...cat
        }))
        setAllCategories(tempCategories)

        tempCategories = tempCategories.sort((a, b) => ((a.ordenacao !== null ? a.ordenacao : 100) > (b.ordenacao !== null ? b.ordenacao : 100)) ? 1 : -1)

        data = tempCategories

        if (location === 'home' || showAllProducts) {
          tempCategories = data.slice(0, 4)
        }
        else {
          tempCategories = data
        }

        setCategories(tempCategories)
      } catch (error) {
        console.error(error)
      }
    }

    loadApi();
    window.scrollTo(0, 0)
  }, []);

  function handleShowAllProducts(value) {
    setShowAllProducts(value)
    setCategories(allCategories)
  }

  


  return (
    <div className="ctprd-div-content">
      <div className="ctprd-div-header">
        <span className="lbl-nossos">Nossos</span>
        <span className="lbl-produtos">Produtos</span>
      </div>

      {
        categories.length > 0 ?
          categories.map(cat => (
            <div key={cat.id} className="ctprd-div-category-item" style={(cat.imagem_background && cat.imagem_background.url ? { backgroundImage: `url(${cat.imagem_background.url})` } : null)}>
              <div className="ctprd-div-category-gradient">
                <button className="ctprd-category-button" onClick={() => handleCategoryOpen(cat)}
                  style={{ borderBottom: (cat.isOpen ? 'none' : '') }}>
                  <div className="ctprd-button-label">{cat.Titulo}</div>
                  {cat.isOpen ? <BsChevronUp size={20} /> : <BsChevronDown size={20} />}
                </button>
                <Fade in={cat.isOpen} timeout={200}>
                  <div className="ctprd-div-content-data" style={{ display: cat.isOpen ? "" : "none" }}>
                    <span className="ctprd-lbl-category-title">{cat.Descricao}</span>
                    <ProdutosCarousel categoria={cat.id} />
                  </div>
                </Fade>
              </div>
            </div>
          )) : <LoadingProducts />
      }

      { location === 'home' ?
        <div className="ctprd-div-category-gradient">
          <Link to="/produtos">
            <button className="ctprd-btn-see-more" onClick={() => { handleShowAllProducts(true) }}>
              <span className="ctprd-label-see-more">+ PRODUTOS</span>
            </button>
          </Link>
        </div>
        : ''}

    </div >
  )
}

export default CategoriasProdutos;