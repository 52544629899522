import ReactHtmlParser from 'react-html-parser'

export function formatarChaveDeAcessoNfe(chave) {
  if (chave.length !== 44) return chave;
  else {
    return chave.split("").reduceRight(function (elemento, anterior) {
      var temp = anterior + elemento;
      if (temp.replace(/\s/g, " ").length % 4 === 0) return " " + temp;
      else return temp;
    });
  }
}
export const distanceMapLimit = 50000 //50km - distances of the pages where find and technical assistance 

export function simpleText2br(text) {
  if (text && text !== "") {
    const newText = text.replace(/(?:\r\n|\r|\n)/g, '<br />')
    const newTextformated = [ReactHtmlParser(newText)]

    return newTextformated
  }
  
  return ""
}


///https://www.movable-type.co.uk/scripts/latlong.html
export async function getDistanceBetweenPoints(lat1, lon1, lat2, lon2) {
  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lon2 - lon1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // in metres
  return parseFloat(distance.toFixed(2))
}

export function convertDateFormat(date) {

  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6, 10);

  const dateFormatted = year + '-' + month + '-' + day

  return dateFormatted
}