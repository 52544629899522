import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { BsDownload } from "react-icons/bs";
import { FiHeadphones, FiGlobe } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";

import FbIcon from "../../assets/facebook-icon.svg";
import InstagramIcon from "../../assets/instagram-icon.svg";
import YoutubeIcon from "../../assets/youtube-icon.svg";
import PinterestIcon from "../../assets/pinterest-icon.svg";
import LinkedinIcon from "../../assets/linkedin-icon.svg";
import "./style.scss";

const Footer = () => {
  const [rodape, setRodape] = useState([]);

  useEffect(() => {
    async function loadApi() {
      try {
        const { data } = await api.get(`/elettromec`);

        setRodape(data.rodape);
      } catch (error) {
        console.error(error);
      }
    }

    loadApi();
  }, []);

  return (
    <>
      <hr className="ft-hr" />
      <div className="ft-div-content">
        {
          rodape.link_catalogo &&
          <>
            <BsDownload color={"#ffffff"} />
            <a
              href={rodape.link_catalogo}
              className="ft-lbl-subtitle">
              Download do Folder
            </a>
          </>
        }
        <br />
        <span className="ft-lbl-title">CONTATO</span>
        <br />
        <span className="ft-lbl-address">{rodape.endereco}</span>
        <br />
        <span className="ft-lbl-address">{rodape.complemento}</span>
        <br />
        <span className="ft-lbl-address">CEP {rodape.cep}</span>
        <br />
        <span className="ft-lbl-address">
          <FiHeadphones className="footer-icons" size={16} />
          {rodape.telefone}
        </span>
        <br />
        <span className="ft-lbl-address">
          <AiOutlineMail className="footer-icons" size={16} />
          {rodape.email}
        </span>
        <br />
        <a className="ft-lbl-address" href={rodape.site && rodape.site.toString().includes('http') ? rodape.site : `//${rodape.site}`} target="_blank" rel="noreferrer">
          <FiGlobe className="footer-icons" size={16} />
          {rodape.site}
        </a>
        <br />
        <span className="ft-lbl-title">SIGA-NOS</span> <br />
        <div className="ft-div-icons">
          {rodape.facebook ? (
            <a href={rodape.facebook} target="_blank" rel="noreferrer">
              <img src={FbIcon} alt="fb-icon" />
            </a>
          ) : (
            <></>
          )}
          {rodape.instagram ? (
            <a href={rodape.instagram} target="_blank" rel="noreferrer">
              <img src={InstagramIcon} alt="instagram-icon" />
            </a>
          ) : (
            <></>
          )}
          {rodape.pinterest ? (
            <a href={rodape.pinterest} target="_blank" rel="noreferrer">
              <img src={PinterestIcon} alt="pinterest-icon" />
            </a>
          ) : (
            <></>
          )}
          {rodape.youtube ? (
            <a href={rodape.youtube} target="_blank" rel="noreferrer">
              <img src={YoutubeIcon} alt="youtube-icon" />
            </a>
          ) : (
            <></>
          )}
          {rodape.linkedin ? (
            <a href={rodape.linkedin} target="_blank" rel="noreferrer">
              <img src={LinkedinIcon} alt="youtube-icon" />
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
