import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import './style.scss';

function LoadingProducts() {
  return (
    <>
      {Array(4)
        .fill('')
        .map((_, i) => (
          <div key={i} className="ctprd-div-category-item">
              <div className="ctprd-div-category-gradient">
                <button className="ctprd-category-button" onClick={() => {}}>
                  <div className="ctprd-skeleton">
                    <SkeletonTheme color="#202020" highlightColor="#444">
                      <Skeleton style={{ height: '50px' }}/>
                    </SkeletonTheme>
                  </div>
                </button>
              </div>
            </div>
        ))
      }
    </>
  )
}

export default LoadingProducts;