import api from "../api";

async function login(email, password) {
    try {
        const { data } = await api.post(`/auth/local`, {
            identifier: email,
            password,
        });

        return data;
    } catch (error) {

    }
}

export { login };