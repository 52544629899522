import {
  emailValidatorEmptyMessage, emailValidatorInvalidMessage
} from '../../utils/defaultMessages';

export default function validateInfo(values) {
  let errors = {};

  if (!values.email) {
    errors.email = emailValidatorEmptyMessage;
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = emailValidatorInvalidMessage;
  }

  return errors;
}
