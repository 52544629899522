import {
  passwordValidatorMessage, passwordValidatorEmptyConfirmation, passwordValidatorEquals, passwordValidatorEmptyMessage
} from '../../utils/defaultMessages';
import passwordValidator from "../../utils/passwordValidator";

export default function validateInfo(values) {
  let errors = {};
  // let regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*()_+^&}{:;?.])(?:([0-9a-zA-Z!@#$%;*(){}_+^&])(?!\1)){6,}$/;

  if (!values.password) {
    errors.password = passwordValidatorEmptyMessage;
  }
  if (!passwordValidator(values.password)) {
    errors.password = passwordValidatorMessage;
  }
  if (!values.passwordConfirm) {
    errors.passwordConfirm = passwordValidatorEmptyConfirmation;
  } else if (values.passwordConfirm !== values.password) {
    errors.passwordConfirm = passwordValidatorEquals;
  }
  return errors;
}
