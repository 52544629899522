import React, { useEffect, useState } from 'react'
import { Page, Line, Image, Link, Text, View, Document, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { styles } from './styledPdf'
import { Spinner } from 'reactstrap'
import { BsDownload } from "react-icons/bs";

import api from "../../services/api";

export const PdfProduct = ({ product }) => {
  const [rodape, setRodape] = useState([]);

  useEffect(() => {
    async function loadApi() {
      try {
        const { data } = await api.get(`/elettromec`);

        setRodape(data.rodape);
      } catch (error) {
        console.error(error);
      }
    }

    loadApi();
  }, []);

  const MyDoc = () => ( // Comentar quando quiser utilizar o PDF Viewer
    // return ( // Descomentar quando for utilizar o PDF Viewer
    // <PDFViewer style={{ width: '100%', height: '80vh' }}> {/* Descomentar quando for utilizar o PDF Viewer */}
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logo}>
          <Image src='/eletromec-29.png' style={{ width: '180px' }} />
        </View>
        <View style={styles.divContent}>
          <View style={styles.divHeader}>
            <Text style={styles.divHeaderTitle}>Produto</Text>
          </View>
          {
            product &&
            <>
              <View style={styles.divProject}>
                <View style={styles.divListInfo}>
                  <Text style={styles.listItemRef}>{product.referencia}</Text>
                  <Text style={styles.lblProjectTitle}>{product.titulo}</Text>
                </View>
              </View>
              <div>
                <Line style={styles.line}></Line>
                <View style={styles.divListProduct}>
                  <View style={styles.listImg}>
                    {
                      product.galeria?.length > 0 &&
                      <>
                        <Image src={product.galeria[0].url} style={{ maxWidth: '100%', height: 'auto' }} />
                      </>
                    }
                  </View>
                  <View style={styles.listItem}>

                    {/* <Text style={styles.listItemTitle}>{product.titulo}</Text> */}
                    <Text style={styles.listItemDescription}>{product.descricao}</Text>
                    {
                      product.ficha_tecnica?.length > 0 &&
                      <View style={styles.listeItemTable}>
                        {
                          product.ficha_tecnica.map((item, index) => {
                            return (
                              <View style={styles.listItemTableTr} key={index}>
                                <Text style={styles.listItemTableTh}>{item.label?.value}</Text>
                                <Text style={styles.listItemTableTd}>{item.value}</Text>
                              </View>
                            )
                          })
                        }
                        {
                          (product.link_gabarito?.length > 0 ||
                            product.link_guia_comercial?.length > 0 ||
                            product.link_manual?.length > 0) &&
                          <View style={styles.listItemTableTr}>
                            <Text style={styles.listItemTableTh}>Downloads</Text>
                            <View style={styles.listItemTableTd}>
                              {product.link_gabarito?.length > 0 &&
                                <>
                                  <Text style={styles.listItemDownloadTdInner}>
                                    <Link src={product.link_gabarito} style={styles.listItemDownloadLink}><Image src='/icon-tools.png' style={styles.listItemDownloadLinkIcon} /> Gabarito</Link>
                                  </Text>
                                </>
                              }
                              {product.link_guia_comercial?.length > 0 &&
                                <>
                                  <Text style={styles.listItemDownloadTdInner}>
                                    <Link src={product.link_guia_comercial} style={styles.listItemDownloadLink}><Image src='/icon-gear-fill.png' style={styles.listItemDownloadLinkIcon} /> Guia comercial</Link>
                                  </Text>
                                </>
                              }
                              {product.link_manual?.length > 0 &&
                                <>
                                  <Text style={styles.listItemDownloadTdInner}>
                                    <Link src={product.link_manual} style={styles.listItemDownloadLink}><Image src='/icon-folder-fill.png' style={styles.listItemDownloadLinkIcon} /> Manual</Link>
                                  </Text>
                                </>
                              }
                            </View>
                          </View>
                        }
                      </View>
                    }
                  </View>
                </View>
              </div>
            </>
          }
        </View>
        <View wrap={false}>
          <Line style={styles.line} />
          <View style={styles.contact}>
            <Text style={styles.contactTitle}>Contato</Text>
            <Text>{rodape.endereco}</Text>
            <Text>{rodape.complemento}</Text>
            <Text>CEP {rodape.cep}</Text>
          </View>
          <View style={styles.contactInfo}>
            <Text>{rodape.telefone}</Text>
            <Text>{rodape.email}</Text>
            <Text>{rodape.site}</Text>
          </View>
          <View style={styles.contactSocial}>
            <Text style={styles.contactSocialTitle}>
              Siga-nos
            </Text>
            <Text style={styles.contactSocialContent}>
              {rodape.facebook?.length > 0 &&
                <Link src={rodape.facebook} style={styles.contactSocialItemLink}>
                  <Image src='/icon-facebook.png' style={styles.contactSocialItemLinkIcon} />
                </Link>
              }
              {rodape.instagram?.length > 0 &&
                <Link src={rodape.instagram} style={styles.contactSocialItemLink}>
                  <Image src='/icon-instagram.png' style={styles.contactSocialItemLinkIcon} />
                </Link>
              }
              {rodape.pinterest?.length > 0 &&
                <Link src={rodape.pinterest} style={styles.contactSocialItemLink}>
                  <Image src='/icon-pinterest.png' style={styles.contactSocialItemLinkIcon} />
                </Link>
              }
              {rodape.youtube?.length > 0 &&
                <Link src={rodape.youtube} style={styles.contactSocialItemLink}>
                  <Image src='/icon-youtube.png' style={styles.contactSocialItemLinkIcon} />
                </Link>
              }
              {rodape.linkedin?.length > 0 &&
                <Link src={rodape.linkedin} style={styles.contactSocialItemLink}>
                  <Image src='/icon-linkedin.png' style={styles.contactSocialItemLinkIcon} />
                </Link>
              }
            </Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerPhone}>
            <Image src='/icon-phone.png' style={styles.footerIconPhone} />
            <Text>{rodape.telefone}</Text>
          </View>
          <View style={styles.footerEmail}>
            <Image src='/icon-email.png' style={styles.footerIconEmail} />
            <Text>{rodape.email}</Text>
          </View>
          <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
            `Página ${pageNumber} / ${totalPages}`
          )} />
        </View>
      </Page>
    </Document>
    // </PDFViewer> // Descomentar quando for utilizar o PDF Viewer

  )

  if (!product) {
    return (
      <Spinner className="share-pdf-spiner" color="light" />
    )
  }

  /* Comentar todo este return() quando for utilizar o PDF Viewer */
  return (
    <PDFDownloadLink document={<MyDoc />} fileName={`${product?.titulo} - Elettromec.pdf`}>
      {({ blob, url, loading, error }) => {

        return (
          <div className="share-pdf-button">
            <BsDownload size={25} />
            <span className="share-pdf-span">PDF</span>
          </div>
        )
      }

      }
    </PDFDownloadLink>
  )
}