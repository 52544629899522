export default function validateInfo(values) {
    let errors = {
        projectName: "",
        error: false
    };

    if (!values.projectName || (values.projectName && values.projectName === "")) {
        errors.projectName = "Por favor, informe o nome do projeto";
        errors.error = true
    }

    return errors;
}
